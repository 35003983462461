import * as React from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { Box, Divider, List, Accordion, AccordionDetails, Modal, ListItem, TextField, Skeleton, IconButton, Pagination, ListItemButton, Button } from '@mui/material';
import { Apple, CheckCircle, CopyAll, Delete, Download, Error, Google, Help, Info, Language, Notifications, QrCode, Remove } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import BigSkeleton from '../components/Skeleton';
import Moment from 'moment'
import BadgeFromAppStoreVersion from '../components/appstore/BadgeFromAppStoreVersion';
import { Link } from 'react-router-dom';

export default function AppStore(props) {

    const params = useParams()
    const [loading, setLoading] = React.useState(true)
    const [appleStoreReleases, setAppleStoreReleases] = React.useState([])
    const [version, setVersion] = React.useState("")
    const [appleToken, setAppleToken] = React.useState("")
    const [application, setApplication] = React.useState({name: "", bundleId: "", primaryLocale: ""})
    const [idApple, setIdApple] = React.useState("")

    const load = async () => {
            let idApple = params.id
            let resultAppStoreToken = await fetch("https://mobilekey.otipass.com/appstore", {
                headers: {
                    Authorization: 'Basic YWRtaW46b3RpcGFzcysrYXV0aA==',
                }
            })
            let resultAppStoreTokenJson = await resultAppStoreToken.json()
            setAppleToken(resultAppStoreTokenJson.token)
            setIdApple(idApple)
            await getApps(resultAppStoreTokenJson.token, idApple)
            await getReleases(resultAppStoreTokenJson.token, idApple)
        setLoading(false)
    }

    const getApps = async (token, pIdApple) => {
        let formData = new FormData()
        formData.append("url", "https://api.appstoreconnect.apple.com/v1/apps/" + pIdApple )
        formData.append("token", token)
        let result = await fetch("https://supervision.mobile.otipass.net/appstore/exec.php", { method: 'POST', body: formData })
        let resultJSON = await result.json()
        if (resultJSON.data) {
            setApplication(resultJSON.data.attributes)
            console.log(resultJSON)
        }
    }

    const getReleases = async (token, pIdApple) => {
        let formData = new FormData()
        formData.append("url", "https://api.appstoreconnect.apple.com/v1/apps/" + pIdApple + "/appStoreVersions")
        formData.append("token", token)
        let result = await fetch("https://supervision.mobile.otipass.net/appstore/exec.php", { method: 'POST', body: formData })
        let resultJSON = await result.json()
        if (resultJSON.data) {
            setAppleStoreReleases(resultJSON.data)
        }
    }

    const createRelease = async () => {
        console.log(version.split(".").length)
        if (version.split(".").length == 3) {
            setLoading(true)
            let formData = new FormData()
            formData.append("url", "https://api.appstoreconnect.apple.com/v1/appStoreVersions")
            formData.append("token", appleToken)
            formData.append("data", JSON.stringify({
                "data": {
                    "type": "appStoreVersions",
                    "attributes": {
                        "platform": "IOS",
                        "versionString": version,
                        "copyright": "© " + new Date().getFullYear() + " OTIPASS, Inc.",
                        "releaseType": "AFTER_APPROVAL",
                        "usesIdfa": false
                    },
                    "relationships": {
                        "app": {
                            "data": {
                                "type": "apps",
                                "id": idApple,
                            }
                        }
                    }
                }
            }))

            let result = await fetch("https://supervision.mobile.otipass.net/appstore/exec_post.php", { method: 'POST', body: formData })
            console.log(result)
            load()
        } else {

        }
    }


    React.useEffect(() => {
        load()
    }, [])


    let havePending = appleStoreReleases.filter(version => version.attributes.appStoreState == "PENDING_DEVELOPER_RELEASE" || version.attributes.appStoreState == "PREPARE_FOR_SUBMISSION").length > 0

    return (
        <>
            {
                loading ?
                    <BigSkeleton />
                    :
                    <Paper sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden', px: 3, py: 2 }}>
                        <Typography sx={{ fontSize: 20 }} >
                            {application.name}
                        </Typography>
                        <Typography color='text.secondary' >
                            {application.primaryLocale + " - " + application.bundleId}
                        </Typography>
                        <Divider sx={{ my: 2 }} />
                        <Typography sx={{ fontSize: 20, mb: 2 }}>
                            {"New release"}
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center', justifyContent: 'space-between' }}>
                            <TextField disabled={havePending} placeholder={havePending ? 'Check last release' : 'Version'} value={version} onChange={(event, value) => { setVersion(event.target.value) }} />
                            <Button disabled={havePending} onClick={() => createRelease()} variant='contained' >
                                <Typography>
                                    {havePending ? "Pending" : "Create"}
                                </Typography>
                            </Button>
                        </Box>
                        <Divider sx={{ my: 2 }} />
                        <Box>
                            <Typography sx={{ fontSize: 20 }}>
                                {"Release"}
                            </Typography>
                            <List>
                                {
                                    appleStoreReleases.map(version => {
                                        return (
                                            <ListItem sx={{ p: 0 }} >
                                                <Link style={{ textDecoration: 'none', borderBottomColor: '#6d6d6d', width: '100%' }} to={"/appstore/" + idApple + "/release/" + version.id}>
                                                    <ListItemButton sx={{ alignItems: 'center', alignContent: 'center', alignSelf: 'center', display: 'flex', justifyContent: 'space-between', px: 1 }}>

                                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center' }}>
                                                            <BadgeFromAppStoreVersion version={version.attributes.appStoreState} />
                                                            <Typography color={version.attributes.appStoreState == "REPLACED_WITH_NEW_VERSION" ? 'text.secondary' : 'text.primary'}  >
                                                                {
                                                                    version.attributes.versionString
                                                                }
                                                            </Typography>
                                                        </Box>
                                                        <Typography color={version.attributes.appStoreState == "REPLACED_WITH_NEW_VERSION" ? 'text.secondary' : 'text.primary'}  >
                                                            {
                                                                Moment(version.attributes.createdDate).format("YYYY-MM-DD")
                                                            }
                                                        </Typography>
                                                    </ListItemButton>
                                                </Link>
                                            </ListItem>
                                        )
                                    })
                                }
                            </List>
                        </Box>
                    </Paper>
            }
        </>
    )
}


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 850,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};