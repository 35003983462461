import * as React from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import ListProject from '../requests/List.project'
import ListApplication from '../requests/List.application'
import ListException from '../requests/List.exception'
import FormDataBuilder from '../utils/FormDataBuilder';
import { Box, List, ListItem, ListItemButton, Skeleton, TextField, IconButton } from '@mui/material';
import { ArrowForward, ArrowForwardIos, ArrowLeft, ArrowRightOutlined, CheckCircle, DoNotDisturb, DoNotDisturbOnTotalSilence, Download, Error, Help, Info, Notifications, QrCode, Star, TrendingDown, TrendingFlat, TrendingUp } from '@mui/icons-material';
import _ from 'lodash'
import Update from '../requests/Update.configuration';


export default function StatusContainer(props) {


    const [loading, setLoading] = React.useState(true)
    const [configuration, setConfiguration] = React.useState({})


    const getConfigurations = async () => {
        let result = await fetch("https://supervision.mobile.otipass.net/tools/sniffer.php?url=https://www.otipass.net/configuration/" + props.project.project + ".json")
        let currentConfiguration = await result.json()
        setConfiguration(currentConfiguration.configurations)
        setLoading(false)
    }


    const updateConfiguration = async (field,value) => {
        new Update(
            new FormDataBuilder()
            .set("field",field)
            .set("value",value)
            .set("project",props.project.project)
        ).emit()
        setLoading(true)
        getConfigurations()
    }



    React.useEffect(() => {
        getConfigurations()
    }, [])

    if (loading) {
        return (
            <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }} >
                <Skeleton style={{ width: '100%', height: 40 }} />
            </ListItem>
        )
    }

    return (
        <ListItem sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', px: 2, py: 1 }}>
            <Typography sx={{ flex: 1 }} color="text.primary" >
                {props.project.project.slice(0, 10).capitalize()}
            </Typography>
            {
                props.contents.map(content => {
                    let value = _.get(configuration, content.path)
                    if (content.type == "string") {
                        return (
                            <TextField onChange={(event, value) => { updateConfiguration(content.path,event.target.value); }}  value={value} size='small' sx={{ flex: content.flex, textAlign: 'center', p: 0, m:0,mx: 1, fontSize: 11 }}>

                            </TextField>
                        )
                    } else if (content.type == "boolean") {
                        return value ? <IconButton onClick={() => {updateConfiguration(content.path,"false")}} sx={{p: 0, flex: content.flex, borderRadius: 1}}><CheckCircle color='success'  /></IconButton> : <IconButton onClick={() => {updateConfiguration(content.path,"true")}} sx={{p: 0, flex: content.flex, borderRadius: 1}}><DoNotDisturbOnTotalSilence color='disabled' /></IconButton>
                    } else if (content.type == "list") {
                        return value.map(item => {
                            return (
                                <Box sx={{ flex: content.itemsPath.length, mx: 1 }}>
                                    {
                                        content.itemsPath.map(pItem =>
                                            <Typography color="text.secondary"    >
                                                {_.get(item, pItem)}
                                            </Typography>
                                        )
                                    }
                                </Box>
                            )
                        })
                    }
                })
            }
        </ListItem>
    )
}
