import Abstract from './Abstract'
class Action extends Abstract {

    /*
        - login
        - password
    */
    constructor(formDataBuilder) {
        super()
        this.method = 'POST'
        formDataBuilder.set("auth_action", "authentification")
        this.formData = formDataBuilder.get()
    }
}

export default Action