import * as React from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import FormDataBuilder from '../utils/FormDataBuilder';
import { Box, Divider, MenuItem, InputLabel, FormControl, Select, FormGroup, FormControlLabel, Checkbox, RadioGroup, Radio, FormLabel, List, IconButton, TextField, ListItem, Skeleton, Button } from '@mui/material';
import { BarChart as BarChartIcon, CopyAll, Download, Error, Help, Info, Inventory, Language, Notifications, QrCode, Search, Send } from '@mui/icons-material';
import ListProject from '../requests/List.project'
import StatisticalType from '../requests/List.statisticalType'
import ListStat from '../requests/List.stat'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import QRCode from "react-qr-code"
import moment from 'moment';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { BarChart, Bar, LineChart, Tooltip, XAxis, YAxis, Legend, PieChart, Pie, Cell, Treemap } from 'recharts';

export default function Statistical() {
    const [data, setData] = React.useState([])
    const [projects, setProjects] = React.useState([])
    const [unique, setUnique] = React.useState(false)
    const [client, setClient] = React.useState("client")
    const [requestTime, setRequestTime] = React.useState(0)
    const [max, setMax] = React.useState(0)
    const [loading, setLoading] = React.useState(false)
    const [start, setStart] = React.useState(new Date(moment().set('D', 1)))
    const [end, setEnd] = React.useState(new Date(moment()))
    const [types, setTypes] = React.useState([])
    const [selectProjects, setSelectProjects] = React.useState([])
    const [selectType, setSelectType] = React.useState({ type: [] })
    const [selectTrackerType, setSelectTrackerType] = React.useState({})

    const getProjects = async () => {
        let result = await new ListProject(
            new FormDataBuilder()
        ).emit()
        if (result.error) {
            //TODO error
        } else {
            result.data.unshift({ name: "All", access: "" })
            setProjects(result.data)
            setSelectProjects(["alsace"])
        }
    }

    const getTypes = async () => {
        let resultType = await new StatisticalType(
            new FormDataBuilder()
        ).emit()

        let resultTmp = []
        resultType.data.map(data => {
            data.type = []
            if (data.totalByPeriod !== "0") {
                data.type.push("totalByDay")
                data.type.push("totalByWeek")
                data.type.push("totalByMonth")
                data.type.push("totalBySerial")
            }
            if (data.totalByReference !== "0") {
                data.type.push("totalByReference")
            }
            resultTmp.push(data)
        })
        setSelectType(resultTmp[0].name)
        setSelectTrackerType(resultTmp[0].type[0])
        setTypes(resultTmp)
    }

    React.useEffect(() => {
        getTypes()
        getProjects()
    }, [])
    let currentSelectType = { type: [] }
    types.forEach(pType => {
        if (pType.name == selectType) {
            currentSelectType = pType
        }
    })


    const fetchStats = async () => {
        setLoading(true)
        let time = 0;
        let data = new Array()
        let dataRatio = new Array()
        for (let index = 0; index < selectProjects.length; index++) {
            const project = selectProjects[index];
            let result = await new ListStat(
                new FormDataBuilder()
                    .set('project_key', project)
                    .set('application_key', (client == "client" ? "moon_" : "sun_") + project)
                    .set('period_start', moment(start).format("YYYY-MM-DD"))
                    .set('period_end', moment(end).format("YYYY-MM-DD"))
                    .set('is_unique', unique ? 'true' : 'false')
                    .set('type_key', selectType[0] == "_" ? selectType.slice(1, selectType.length) : selectType)
                    .set('group_key', selectTrackerType)
                    .set('value_filter', null)
            ).emit()
            if (result.error) {
                this.setState({
                    data: [],
                    isSubLoading: false,
                    time: "error"
                })
            } else {
                time = time + result.data.script
                data.push({ label: project, data: result.data.data })
            }
        }
        let formatedData = []
        for (const key in data[0].data) {
            if (selectTrackerType == "totalBySerial" || selectTrackerType == "totalByReference") {
                formatedData.push({ name: data[0].data[key].label, date: key })
            } else {
                formatedData.push({ name: key, date: key })
            }
        }

        let max = 0

        data.forEach((pData) => {
            let index = 0
            for (const key in pData.data) {
                let element = pData.data[key];
                if (formatedData[index][pData.label]) {
                    formatedData[index][pData.label] = formatedData[index][pData.label] + element.quantity
                } else {
                    formatedData[index][pData.label] = element.quantity
                }
                if (element.quantity > max) {
                    max = element.quantity
                }
                index++
            }
        })
        if (selectTrackerType == "totalBySerial" || selectTrackerType == "totalByReference") {
            formatedData = formatedData.map(formatedDataTmp => {
                selectProjects.forEach(selectProjectTmp => {
                    if (formatedDataTmp["quantity"]) {
                        formatedDataTmp["quantity"] = formatedDataTmp["quantity"] + formatedDataTmp[selectProjectTmp]
                    } else {
                        formatedDataTmp["quantity"] = formatedDataTmp[selectProjectTmp]
                    }
                })
                return formatedDataTmp
            })
        }
        setMax(max)
        setData(formatedData)
        setLoading(false)
        setRequestTime(time)
    }
    return (
        <>
            <Paper sx={{ maxWidth: 936, cursor: 'pointer', margin: 'auto', overflow: 'hidden', px: 3, py: 2 }}>
                <Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center' }}>
                        <BarChartIcon sx={{ mr: 1, fontSize: 30 }} htmlColor='rgba(0, 0, 0, 0.8)' />
                        <Typography sx={{ fontSize: 24 }}>
                            {"Big data"}
                        </Typography>
                    </Box>
                </Box>

            </Paper>
            <Paper sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden', mt: 2 }}>
                <Box sx={{ p: 2 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center', mb: 2 }}>
                        <FormControl fullWidth>
                            <Select
                                multiple
                                labelId="project-select"
                                id="project-select"
                                placeholder='Project'
                                value={selectProjects}
                                onChange={(event) => {
                                    setData([])
                                    setSelectProjects(event.target.value)
                                }}
                            >
                                {
                                    projects.map((project, index) => {
                                        return <MenuItem key={index} value={project.access}>{project.name}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>
                        <FormControl sx={{ ml: 1 }} fullWidth>
                            <Select
                                labelId="type-select"
                                id="type-select"
                                placeholder='Type'
                                value={selectType}
                                onChange={(event) => {
                                    setData([])
                                    setSelectType(event.target.value)
                                    types.forEach(pType => {
                                        if (pType.name == event.target.value) {
                                            setSelectTrackerType(pType.type[0])

                                        }
                                    })
                                }}
                            >
                                {
                                    types.map((type, index) => {
                                        return <MenuItem key={index} value={type.name}>{type.description}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Box>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center' }}>
                            <DesktopDatePicker
                                label="Start"
                                inputFormat="DD/MM/yyyy"
                                value={start}
                                onChange={(date) => { setStart(date) }}
                                renderInput={(params) => <TextField {...params} sx={{ mr: 2 }} />}
                            />
                            <DesktopDatePicker
                                label="End"
                                inputFormat="DD/MM/yyyy"
                                value={end}
                                onChange={(date) => { setEnd(date) }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </Box>
                    </LocalizationProvider>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignContent: 'center', alignItems: 'center', mt: 1 }}>
                        <FormControl sx={{ ml: 1 }}>
                            <RadioGroup
                                onChange={(event) => {
                                    setData([])
                                    setSelectTrackerType(event.target.value)
                                }} value={selectTrackerType} row >
                                {
                                    currentSelectType.type.map((el) => {
                                        return <FormControlLabel value={el} control={<Radio />} label={el === "totalByReference" ? "Par Item" : el === "totalBySerial" ? "Par Serie" : el === "totalByWeek" ? "Hebdomadaire" : el === "totalByDay" ? "Journalier" : el === "totalByMonth" ? "Mensuel" : "Par Item"} />
                                    })
                                }
                            </RadioGroup>
                        </FormControl>
                        <FormControlLabel sx={{ ml: 1 }} aria-labelledby="plat-label" control={<Checkbox onChange={(event) => {
                            setData([])
                            setUnique(event.target.checked)
                        }} checked={unique} defaultChecked />} label="Unique" />
                        <Button variant='contained' onClick={() => { fetchStats() }} sx={{ borderRadius: 1 }} disabled={loading} >
                            <Typography sx={{ fontWeight: 'bold' }} >
                                {"Search"}
                            </Typography>
                        </Button>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignContent: 'center', alignItems: 'center', mt: 1 }}>

                        <FormControl sx={{ ml: 1 }}>
                            <RadioGroup
                                onChange={(event) => {
                                    setClient(event.target.value)
                                }} value={client} row >
                                <FormControlLabel value={"client"} control={<Radio />} label={"Client"} />
                                <FormControlLabel value={"control"} control={<Radio />} label={"Controller"} />
                            </RadioGroup>
                        </FormControl>
                    </Box>
                </Box>
            </Paper>
            {
                data.length > 0 ?
                    <Paper sx={{ maxWidth: 936, maxHeight: 500,overflow:'scroll', margin: 'auto', mt: 2 }}>
                        <Box sx={{ p: 2, display: 'flex', justifyContent: 'center' }}>
                            {
                                selectTrackerType == "totalBySerial" || selectTrackerType == "totalByReference" ?

                                    data.length > 20 ?
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>reference</TableCell>
                                                    <TableCell>value</TableCell>
                                                    <TableCell>counter</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {data.map((entry,index) => {
                                                    console.log(entry)
                                                    return (
                                                        <TableRow
                                                        key={index}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            {entry.date}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {entry.name}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {entry.quantity}
                                                        </TableCell>
                                                    </TableRow>
                                                    )
                                                })}
                                            </TableBody>
                                        </Table>
                                        :
                                        <PieChart width={936} height={400} >
                                            <Pie data={data} dataKey={"quantity"} nameKey={"name"}  >
                                                {
                                                    data.map((entry, index) => (
                                                        <Cell key={`cell-${index}`} fill={colors[index]} />
                                                    ))
                                                }
                                            </Pie>
                                            <Legend />
                                            <Tooltip />
                                        </PieChart>
                                    :
                                    <BarChart width={900} height={400} data={data}>
                                        {
                                            selectProjects.map((project, index) => {
                                                return <Bar fill={colors[index]} dataKey={project} />
                                            })
                                        }
                                        <Tooltip />
                                        <YAxis type="number" tickCount={10} domain={[0, parseInt((max / 100) + 1) * 100]} dataKey="quantity" />
                                        <XAxis tickFormatter={(value, index) => {
                                            switch (selectTrackerType) {
                                                case "totalByDay":
                                                    return index % 2 == 0 ? moment(value).format("DD") : ""
                                                case "totalByWeek":
                                                    return index % 4 == 0 ? moment().week(value.split("-")[0]).format("MM") : ""
                                                case "totalByMonth":
                                                    return moment(value).format("MM")
                                            }
                                        }} dataKey="date" />
                                        <Legend />
                                    </BarChart>
                            }
                        </Box>
                    </Paper> : null
            }
        </>

    )
}

const data01 = [
    {
        "name": "Group A",
        "value": 400
    },
    {
        "name": "Group B",
        "value": 300
    },
    {
        "name": "Group C",
        "value": 300
    },
    {
        "name": "Group D",
        "value": 200
    },
    {
        "name": "Group E",
        "value": 278
    },
    {
        "name": "Group F",
        "value": 189
    }
];

const colors = [
    "#e57373",
    "#4fc3f7",
    "#81c784",
    "#ffb74d",
    "#ce93d8",
    "#ab47bc",
    "#388e3c",
    "#e57373",
    "#4fc3f7",
    "#81c784",
    "#ffb74d",
    "#ce93d8",
    "#ab47bc",
    "#388e3c",
    "#e57373",
    "#4fc3f7",
    "#81c784",
    "#ffb74d",
    "#ce93d8",
    "#ab47bc",
    "#388e3c",
]
//return index % 4 == 0 ? moment().week(value.split("-")[0]).format("MM") : 
/*

                                <Bar fill='#e57373' dataKey="quantityLast" />
                                <Bar fill='#4fc3f7' dataKey="quantity" />
                                */