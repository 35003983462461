
import Abstract from './Abstract'

class Update extends Abstract {

    constructor(formDataBuilder) {
        super()
        this.method = 'POST'
        formDataBuilder.set("front_action", "update_configuration")
        formDataBuilder.set("project_platform", "www")
        this.formData = formDataBuilder.get()
    }
}

export default Update

