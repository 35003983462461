import * as React from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import FormDataBuilder from '../utils/FormDataBuilder';
import { Box, TextField, AccordionSummary, Accordion, AccordionDetails, Modal, Icon, Button } from '@mui/material';
import { CheckCircle, CopyAll, Lock, Error, Help, Info, Inventory, Language, Notifications, QrCode, Settings, AddBoxRounded } from '@mui/icons-material';
import ListBuild from '../requests/List.build'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ActionAuthentification from "../requests/Action.authentification"
import QRCode from "react-qr-code"

export default function Auth(props) {

    const [login, setLogin] = React.useState("")
    const [password, setPassword] = React.useState("")
    const [checked, setChecked] = React.useState(true)

    const auth =  async () => {
        let result = await new ActionAuthentification(
            new FormDataBuilder()
                .set('login', login)
                .set('password', password)
        ).emit()
        if (result.error) {
            setLogin("")
            setPassword("")
        } else {
            localStorage.setItem("token", result.data)
            if (checked) {
                localStorage.setItem("login", login)
                localStorage.setItem("password", password)
            }
            props.open()
        }
    }
 
    React.useEffect(() => {
        if(localStorage.getItem("login")) {
            setLogin(localStorage.getItem("login"))
        }
        if(localStorage.getItem("password")) {
            setPassword(localStorage.getItem("password"))
        }
    }, [])

    return (
        <>
            <Paper sx={{ maxWidth: 450, cursor: 'pointer', margin: 'auto', overflow: 'hidden', px: 3, py: 2 }}>
                <Box sx={{textAlign:'center'}}>
                        <Typography sx={{ fontSize: 26 }}>
                            {"Protected area"}
                        </Typography>
                        <Typography color='text.secondary'>
                            {"Login is required"}
                        </Typography>
                </Box>

            </Paper>
            <Paper sx={{ maxWidth: 450, margin: 'auto', overflow: 'hidden', mt: 2 }}>
                <Box sx={{ p: 2, display:'flex', flexDirection:'column' }}>
                    <TextField style={{width: '100%'}} placeholder='email' type='email'  value={login} onChange={(event, value) => { setLogin(event.target.value)}} />
                    <TextField style={{width: '100%'}} placeholder='password' type='password' sx={{my: 2}} value={password} onChange={(event, value) => { setPassword(event.target.value)}} />
                    <Button onClick={() => {auth()}} style={{width: '100%'}} variant='contained'>Login</Button>
                </Box>
            </Paper>
        </>

    )
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 360,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};