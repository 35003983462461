import Abstract from './Abstract'
import Constants from "../constants.json"

class List extends Abstract {

    constructor(formDataBuilder, test) {
        super()
        if(test) {
            this.baseUrl = Constants.testUrl
        }
        this.method = 'POST'
        formDataBuilder.set("front_action", "detail_annuaire")
        this.formData = formDataBuilder.get()
    }
}

export default List