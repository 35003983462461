class Abstract {

    constructor() {
        this.formData = new FormData()
    }


    async emit() {
        let fetchRes = await fetch("https://supervision.mobile.otipass.net/ws.php?token=" + localStorage.getItem("token"), {
            method: this.method,
            body: this.formData
        })
        try {
            let jsonRes = await fetchRes.json()
            if(jsonRes.status===0) {
                return {
                    error: false,
                    message: 'OK',
                    data: jsonRes.data
                }
            } else {
                return {
                    error: true, 
                    message: jsonRes.message, 
                    data: ''
                }
            }
        } catch (error) {
            return {error: true, message: 'Internal error'}
        }
    }
}

export default Abstract