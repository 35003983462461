import * as React from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import ListApplication from '../requests/List.application'
import ViewDeleteException from '../requests/Delete.exception'
import ListException from '../requests/List.exception'
import FormDataBuilder from '../utils/FormDataBuilder'
import ListStat from "../requests/List.stat"
import { Box, Divider, List, Accordion, AccordionDetails, Modal, ListItem, ListItemButton, Skeleton, IconButton, Pagination } from '@mui/material';
import { Apple, CheckCircle, CopyAll, Delete, Download, Error, Google, Help, Info, Language, Notifications, QrCode, Remove } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import BigSkeleton from '../components/Skeleton';
import Moment from 'moment'
import { BarChart, Bar, XAxis, YAxis, Legend } from 'recharts';
import BadgeFromAppStoreVersion from '../components/appstore/BadgeFromAppStoreVersion';
import moment from 'moment';
import JSONPretty from 'react-json-pretty';

export default function Archives(props) {

    const params = useParams()

    const load = async () => {
        let resultMoonException = await new ListException(
            new FormDataBuilder()
                .set('project_key', params.id)
                .set('application_key', "moon_" + params.id)
        ).emit()
        let resultSunException = await new ListException(
            new FormDataBuilder()
                .set('project_key', params.id)
                .set('application_key', "sun_" + params.id)
        ).emit()

        if (resultMoonException.error) {
            setStatusMoon("unknown")
        } else if (resultMoonException.data.length > 0 || resultSunException.data.length > 0) {
            setExceptionMoon(resultMoonException.data.reverse())
            setExceptionSun(resultSunException.data.reverse())
            setStatusMoon("error")
        } else {
            setExceptionMoon([])
            setStatusMoon("success")
        }

        let resultApplication = await new ListApplication(
            new FormDataBuilder()
                .set("project_key", params.id)
        ).emit()
        if (resultApplication.error) {
            return
        }
        let currentApplicationMoon
        resultApplication.data.forEach(application => {
            if (application.type == 1) {
                currentApplicationMoon = application
                setApplicationMoon(application)
            }
        })
        setLoading(false)
        console.log(localStorage.getItem("showScreenshot"))
        if (localStorage.getItem("showScreenshot") === "true") {


            let result = await new ListStat(
                new FormDataBuilder()
                    .set('project_key', params.id)
                    .set('application_key', "moon_" + params.id)
                    .set('period_start', Moment().subtract(4, "month").format("YYYY-MM-DD"))
                    .set('period_end', Moment().format("YYYY-MM-DD"))
                    .set('is_unique', 'false')
                    .set('type_key', "application-start")
                    .set('group_key', "totalByWeek")
                    .set('value_filter', null)
            ).emit()
            if (result.error) {
                return
            }
            let resultLast = await new ListStat(
                new FormDataBuilder()
                    .set('project_key', params.id)
                    .set('application_key', "moon_" + params.id)
                    .set('period_start', Moment().subtract(1, "year").subtract(4, "month").format("YYYY-MM-DD"))
                    .set('period_end', Moment().subtract(1, "year").format("YYYY-MM-DD"))
                    .set('is_unique', 'false')
                    .set('type_key', "application-start")
                    .set('group_key', "totalByWeek")
                    .set('value_filter', null)
            ).emit()
            if (resultLast.error) {
                return
            }
            let lastYear = Moment().subtract(1, "year").format("YYYY")
            let currentYear = Moment().format("YYYY")
            let startData = []
            let maxStart = 0
            for (const key in result.data.data) {
                let currentValue = result.data.data[key]
                currentValue.date = key
                if (resultLast.data.data[key.replace(currentYear, lastYear)] == undefined) {
                    resultLast.data.data[key.replace(currentYear, lastYear)] = {
                        quantity: 0,
                        data: []
                    }
                }

                currentValue.quantityLast = resultLast.data.data[key.replace(currentYear, lastYear)].quantity
                if (currentValue.quantity > maxStart) {
                    maxStart = currentValue.quantity
                }
                if (currentValue.quantityLast > maxStart) {
                    maxStart = currentValue.quantityLast
                }
                startData.push(currentValue)
            }
            startData.shift()
            startData.pop()
            setStatsStart(startData)
            setMaxStart(maxStart)
            let resultAssociate = await new ListStat(
                new FormDataBuilder()
                    .set('project_key', params.id)
                    .set('application_key', "moon_" + params.id)
                    .set('period_start', Moment().subtract(4, "month").format("YYYY-MM-DD"))
                    .set('period_end', Moment().format("YYYY-MM-DD"))
                    .set('is_unique', 'false')
                    .set('type_key', "associate-action")
                    .set('group_key', "totalByWeek")
                    .set('value_filter', null)
            ).emit()
            if (resultAssociate.error) {
                return
            }
            let resultAssociateLast = await new ListStat(
                new FormDataBuilder()
                    .set('project_key', params.id)
                    .set('application_key', "moon_" + params.id)
                    .set('period_start', Moment().subtract(1, "year").subtract(4, "month").format("YYYY-MM-DD"))
                    .set('period_end', Moment().subtract(1, "year").format("YYYY-MM-DD"))
                    .set('is_unique', 'false')
                    .set('type_key', "associate-action")
                    .set('group_key', "totalByWeek")
                    .set('value_filter', null)
            ).emit()
            if (resultAssociateLast.error) {
                return
            }
            let maxAssociate = 0
            let associateData = []
            for (const key in resultAssociate.data.data) {
                let currentValue = resultAssociate.data.data[key]
                currentValue.date = key
                if (resultAssociateLast.data.data[key.replace(currentYear, lastYear)] == undefined) {
                    resultAssociateLast.data.data[key.replace(currentYear, lastYear)] = {
                        quantity: 0,
                        data: []
                    }
                }
                currentValue.quantityLast = resultAssociateLast.data.data[key.replace(currentYear, lastYear)].quantity
                if (currentValue.quantity > maxAssociate) {
                    maxAssociate = currentValue.quantity
                }
                if (currentValue.quantityLast > maxAssociate) {
                    maxAssociate = currentValue.quantityLast
                }
                associateData.push(currentValue)
            }
            associateData.shift()
            associateData.pop()
            setMaxAssociate(maxAssociate)
            setStatsAssociate(associateData)
        }

        setLoadingState(false)
    }

    const removeException = async (id) => {
        try {
            let result = await new ViewDeleteException(
                new FormDataBuilder()
                    .set('front_action', "delete_exception")
                    .set('exception_key', id)
            ).emit()
        } catch (error) { }
        let resultMoonException = await new ListException(
            new FormDataBuilder()
                .set('project_key', params.id)
                .set('application_key', "moon_" + params.id)
        ).emit()
        let resultSunException = await new ListException(
            new FormDataBuilder()
                .set('project_key', params.id)
                .set('application_key', "sun_" + params.id)
        ).emit()

        if (resultMoonException.error) {
            setStatusMoon("unknown")
        } else if (resultMoonException.data.length > 0 || resultSunException.data.length > 0) {
            setExceptionMoon(resultMoonException.data.reverse())
            setExceptionSun(resultSunException.data.reverse())
            setStatusMoon("error")
        } else {
            setExceptionSun([])
            setExceptionMoon([])
            setStatusMoon("success")
        }

    }


    const [loadingStats, setLoadingState] = React.useState(true)
    const [loading, setLoading] = React.useState(true)
    const [statusMoon, setStatusMoon] = React.useState("success")
    const [exceptionMoon, setExceptionMoon] = React.useState([])
    const [exceptionSun, setExceptionSun] = React.useState([])
    const [statsStart, setStatsStart] = React.useState([])
    const [statsAssociate, setStatsAssociate] = React.useState([])
    const [maxAssociate, setMaxAssociate] = React.useState(0)
    const [maxStart, setMaxStart] = React.useState(0)
    const [applicationMoon, setApplicationMoon] = React.useState({ appstore: "", playstore: "" })
    const [exceptionMoonPage, setExceptionMoonPage] = React.useState(1)
    const [exceptionSunPage, setExceptionSunPage] = React.useState(1)
    const [currentDetail, setCurrentDetail] = React.useState(null)

    React.useEffect(() => {
        load()
    }, [])
    return (
        <>
            {
                loading ?
                    <BigSkeleton />
                    :
                    <Paper sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden', px: 3, py: 2 }}>
                        <Box sx={{ alignItems: 'center', alignContent: 'center', alignSelf: 'center', display: 'flex', justifyContent: 'space-between' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center' }}>
                                {
                                    statusMoon == "unknown" ?
                                        <Help sx={{ mr: 1 }} color='disabled' />
                                        : statusMoon == "error" ?
                                            <Error sx={{ mr: 1 }} color='error' /> :
                                            <CheckCircle sx={{ mr: 1 }} color='success' />
                                }
                                <Typography sx={{ fontSize: 20 }} >
                                    {params.id.capitalize() + " - bénéficiaire"}
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center' }}>
                                {
                                    applicationMoon.playstore && applicationMoon.playstore.length > 0 ?
                                        <IconButton onClick={() => { window.open(applicationMoon.playstore) }}>
                                            <Google />
                                        </IconButton> : null
                                }
                                {
                                    applicationMoon.appstore && applicationMoon.appstore.length > 0 ?
                                        <IconButton onClick={() => { window.open(applicationMoon.appstore) }}>
                                            <Apple />
                                        </IconButton> : null
                                }
                            </Box>
                        </Box>
                        <Divider sx={{ my: 2 }} />
                        {localStorage.getItem("showScreenshot") == "true" ? loadingStats ?
                            <List>
                                <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }} >
                                    <Skeleton style={{ width: '100%', height: 40 }} />
                                </ListItem>
                                <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }} >
                                    <Skeleton style={{ width: '100%', height: 40 }} />
                                </ListItem>
                                <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }} >
                                    <Skeleton style={{ width: '100%', height: 40 }} />
                                </ListItem>
                                <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }} >
                                    <Skeleton style={{ width: '100%', height: 40 }} />
                                </ListItem>
                                <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }} >
                                    <Skeleton style={{ width: '100%', height: 40 }} />
                                </ListItem>
                                <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }} >
                                    <Skeleton style={{ width: '100%', height: 40 }} />
                                </ListItem>

                            </List>
                            :
                            <>
                                <Box sx={{ mt: 2 }}>
                                    <Typography sx={{ fontSize: 20, mb: 1 }}>
                                        {"Trendline"}
                                    </Typography>
                                    <Typography color='text.secondary' sx={{ ml: 2, mb: 2 }}>
                                        {"Start / Week"}
                                    </Typography>
                                    <BarChart width={800} height={200} data={statsStart}>
                                        <Bar fill='#e57373' dataKey="quantityLast" />
                                        <Bar fill='#4fc3f7' dataKey="quantity" />
                                        <YAxis type="number" tickCount={10} domain={[0, parseInt((maxStart / 100) + 1) * 100]} dataKey="quantity" />
                                        <XAxis tickFormatter={(value, index) => { return index % 4 == 0 ? Moment().week(value.split("-")[0]).format("MM") : "" }} dataKey="date" />
                                    </BarChart>
                                </Box>
                                <Box>
                                    <Typography color='text.secondary' sx={{ ml: 2, mb: 2 }}>
                                        {"Associate / Week"}
                                    </Typography>
                                    <BarChart width={800} height={200} data={statsAssociate}>
                                        <Bar fill='#e57373' dataKey="quantityLast" />
                                        <Bar fill='#4fc3f7' dataKey="quantity" />
                                        <YAxis type="number" tickCount={10} domain={[0, parseInt((maxAssociate / 10) + 1) * 10]} dataKey="quantity" />
                                        <XAxis tickFormatter={(value, index) => { return index % 4 == 0 ? Moment().week(value.split("-")[0]).format("MM") : "" }} dataKey="date" />
                                        <Legend formatter={(value, entry, index) => (<span style={{ color: entry.color }}>{value == "quantityLast" ? Moment().subtract(1, "year").format("YYYY") : Moment().format("YYYY")}</span>)} />
                                    </BarChart>
                                </Box>
                            </> : null}
                        {localStorage.getItem("showScreenshot") == "true" ? <Divider sx={{ my: 2 }} /> : null}
                        <Box sx={{ height: exceptionMoon.length == 0 ? 'auto' : 325 }}>
                            <Typography sx={{ fontSize: 20 }}>
                                {"From client"}
                            </Typography>
                            <List>
                                {
                                    exceptionMoon.length == 0 ?
                                        <Typography color='text.secondary' sx={{ mt: 1 }} >
                                            {"Nothing at this time"}
                                        </Typography>
                                        :
                                        exceptionMoon.slice((exceptionMoonPage - 1) * 5, 5 * (exceptionMoonPage - 1) + 5).map(exception => {
                                            return (
                                                <ListItem sx={{ p: 0 }} >
                                                    <ListItemButton onClick={() => setCurrentDetail(exception)} sx={{ p: 0.5, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                        <Box sx={{ display: 'flex', flexDirection: 'row', ml: 1 }}>
                                                            <Typography >
                                                                {exception.message.split("|")[0].slice(0, 80)}
                                                            </Typography>
                                                        </Box>
                                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center' }}>
                                                            <Typography >
                                                                {Moment(exception.date).fromNow().capitalize()}
                                                            </Typography>
                                                            <IconButton onClick={(event) => { event.stopPropagation(); removeException(exception.id) }} sx={{ borderRadius: 0, ml: 2 }}>
                                                                <Remove />
                                                            </IconButton>
                                                        </Box>
                                                    </ListItemButton>
                                                </ListItem>
                                            )
                                        })
                                }
                                {
                                    exceptionMoon.length > 5 ?
                                        <Pagination sx={{ mt: 2 }} page={exceptionMoonPage} onChange={(event, page) => { setExceptionMoonPage(page) }} hidePrevButton hideNextButton count={Math.ceil(exceptionMoon.length / 5)} /> : null
                                }
                            </List>
                        </Box>
                        <Divider sx={{ my: 2 }} />
                        <Box sx={{ height: exceptionSun.length == 0 ? 'auto' : 325 }}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center' }}>
                                <Typography sx={{ fontSize: 20 }}>
                                    {"From control"}
                                </Typography>
                            </Box>
                            <List>
                                {
                                    exceptionSun.length == 0 ?
                                        <Typography color='text.secondary' sx={{ mt: 1 }} >
                                            {"Nothing at this time"}
                                        </Typography>
                                        :
                                        exceptionSun.slice((exceptionSunPage - 1) * 5, 5 * (exceptionSunPage - 1) + 5).map(exception => {
                                            return (
                                                <ListItem sx={{ p: 0 }} >
                                                    <ListItemButton onClick={() => setCurrentDetail(exception)} sx={{ p: 0.5, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                        <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1 }}>
                                                            <Typography >
                                                                {exception.message.split("|")[0].slice(0, 80)}
                                                            </Typography>
                                                        </Box>
                                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center' }}>
                                                            <Typography >
                                                                {Moment(exception.date).fromNow().capitalize()}
                                                            </Typography>
                                                            <IconButton onClick={(event) => { event.stopPropagation(); removeException(exception.id) }} sx={{ borderRadius: 0, ml: 2 }}>
                                                                <Remove />
                                                            </IconButton>
                                                        </Box>
                                                    </ListItemButton>
                                                </ListItem>
                                            )
                                        })
                                }
                                {
                                    exceptionSun.length > 5 ?
                                        <Pagination sx={{ mt: 2 }} page={exceptionSunPage} onChange={(event, page) => { setExceptionSunPage(page) }} hidePrevButton hideNextButton count={Math.ceil(exceptionSun.length / 5)} /> : null
                                }
                            </List>
                        </Box>
                    </Paper>
            }
            {
                currentDetail ?
                    <Modal
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        open={currentDetail}
                        onClose={() => setCurrentDetail(null)}
                    >
                        <Box sx={style}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1 }}>
                                <Typography sx={{ fontSize: 20 }} >
                                    {currentDetail.version}
                                </Typography>
                                <Typography style={{ cursor: 'pointer' }} onClick={() => { window.open("https://www.otipass.net/ot/console/" + params.id + "/device/list/sort/desc/order/device.iddevice/serial/" + currentDetail.serial) }} >
                                    {currentDetail.model + " - " + currentDetail.serial}
                                </Typography>
                                <Typography style={{ cursor: 'pointer' }} onClick={() => { window.open("/history/" + params.id + "/" + currentDetail.serial) }} >
                                    {"Device history"}
                                </Typography>
                                <Divider sx={{ my: 2 }} />
                                <Typography sx={{ overflow: 'auto' }} >
                                    {currentDetail.message.split("|")[0]}
                                </Typography>
                                {
                                    currentDetail.message.split("|").length > 1 &&
                                    <JSONPretty style={{ fontSize: 14, color: '#333333', fontFamily: '"Roboto","Helvetica","Arial",sans-serif' }} id="json-pretty" data={JSON.parse(currentDetail.message.split("|")[1])}></JSONPretty>
                                }
                                <Divider sx={{ my: 2 }} />
                                <Typography color='text.secondary'  >
                                    {moment(currentDetail.date).format("DD/MM HH:mm:ss")}
                                </Typography>
                                <Typography color='text.secondary'  >
                                    {currentDetail.event + "-" + currentDetail.id}
                                </Typography>
                            </Box>
                        </Box>
                    </Modal> : null
            }
        </>
    )
}


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 850,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};