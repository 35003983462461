import * as React from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { Box, List, ListItem, Tab, Tabs, Divider } from '@mui/material';
import BigSkeleton from '../components/Skeleton';
import ConfigurationContainer from '../components/ConfigurationContainer';

const contents = {
    "configuration": [
        {
            name: "Prefixe",
            path: "prefixe",
            type: "string",
            flex: 1
        }, {
            name: "NFC",
            path: "nfc",
            type: "boolean",
            flex: 1
        }, {
            name: "QrCode",
            path: "qrcode",
            type: "boolean",
            flex: 1
        }, {
            name: "Sale",
            path: "sale",
            type: "boolean",
            flex: 1
        }, {
            name: "Force entry",
            path: "force_entry",
            type: "boolean",
            flex: 1
        }, {
            name: "Calypso",
            path: "keyboard_calypso",
            type: "boolean",
            flex: 1
        }, {
            name: "Hexadecimal",
            path: "keyboard_hex",
            type: "boolean",
            flex: 1
        }, {
            name: "AMC",
            path: "amc",
            type: "boolean",
            flex: 1
        },
    ],
    "option": [
        {
            name: "Multiple entry",
            path: "multiple_entry",
            type: "boolean",
            flex: 1
        }, {
            name: "Speed entry",
            path: "multiple_entry_speed",
            type: "boolean",
            flex: 1
        }, {
            name: "Only online",
            path: "only_online",
            type: "boolean",
            flex: 1
        }, {
            name: "Suggest",
            path: "suggest",
            type: "boolean",
            flex: 1
        }, {
            name: "Package dynamic",
            path: "dynamic_package",
            type: "boolean",
            flex: 1
        }, {
            name: "Pass France",
            path: "pass_france",
            type: "boolean",
            flex: 1
        }
    ],
    "transport": [
        {
            name: "With transport",
            path: "with_transport",
            type: "boolean",
            flex: 1
        },
        {
            name: "With specific service",
            path: "with_service",
            type: "string",
            flex: 2
        },
        {
            name: "Access token",
            path: "access_token",
            type: "string",
            flex: 4
        }
    ]
}


export default function Controller() {

    const [projects, setProjects] = React.useState([])
    const [tab, setTab] = React.useState("configuration")

    const getProjects = async () => {
        let result = await fetch("https://supervision.mobile.otipass.net/tools/sniffer.php?url=https://www.otipass.net/projects.json")
        let resultJSON = await result.json()
        setProjects(resultJSON.sort((a, b) => {
            console.log(a)
            if (a.project.toUpperCase() > b.project.toUpperCase()) {
                return 1
            }
            return -1
        }))
    }

    React.useEffect(() => {
        getProjects()
    }, [])


    return (
        projects.length == 0 ?
            <BigSkeleton />
            :
            <>
                <Paper sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden' }}>
                    <Box>
                        <Tabs value={tab} onChange={(event, value) => { setTab(value) }} >
                            <Tab sx={{ width: 100, fontSize: 16 }} value={"configuration"} label="Configuration" />
                            <Tab sx={{ width: 100, fontSize: 16 }} value={"option"} label="Option" />
                            <Tab sx={{ width: 100, fontSize: 16 }} value={"transport"} label="Transport" />
                        </Tabs>
                    </Box>
                </Paper>
                <Paper sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden', mt: 2 }}>
                    <List>
                        <ListItem sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', py: 1, pb: 1.5 }} >
                            <Typography color="text.primary" sx={{ width: 100, fontSize: 16, flex: 1 }}  >
                                Project
                            </Typography>
                            {
                                contents[tab].map(content => {
                                    return (
                                        <Typography color="text.secondary" sx={{ flex: content.flex, textAlign: 'center' }}   >
                                            {content.name}
                                        </Typography>
                                    )
                                })
                            }
                        </ListItem>
                        <Divider />
                    </List>
                    {
                        projects.map((project, index) => {
                            return (
                                <ConfigurationContainer contents={contents[tab]} project={project} />
                            )
                        })
                    }
                </Paper>
            </>
    )
}
