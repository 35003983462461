//@type Class:FormDataBuilder => Créé un FormData pour les requests, envoyer les bons paramètres
class FormDataBuilder {
    constructor() {
        this.formData = new FormData()
    }

    //@type Function:set => Envoie un objet dans le FormBuilder
    //@type (set)Key:key => La clé de l'objet
    //@type (set)Value:value => La valeur de l'objet
    set(key,value) {
        this.formData.append(key,value)
        return this
    }

    //@type Function:get => Récupère tout le FormData avec ce qui lui a été "set"
    get() {
        return this.formData
    }


    getOne(key) {
        this.formData.get(key)
    }

}

export default FormDataBuilder