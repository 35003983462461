import * as React from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/material';
export default function Logout(props) {

    React.useEffect(() => {
        localStorage.setItem("password", "")
        localStorage.setItem("login", "")
        window.location.replace('/')
    }, [])

    return (
        <>
            <Paper sx={{ maxWidth: 450, cursor: 'pointer', margin: 'auto', overflow: 'hidden', px: 3, py: 2 }}>
                <Box sx={{ textAlign: 'center' }}>
                    <Typography sx={{ fontSize: 26 }}>
                        {"Disconnected"}
                    </Typography>
                </Box>
            </Paper>
        </>

    )
}