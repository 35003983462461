import * as React from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { Box, List, ListItem, ListItemButton, Skeleton, Divider } from '@mui/material';
import { ArrowForward, ArrowForwardIos, ArrowLeft, ArrowRightOutlined, Cases, CheckCircle, Download, Error, Help, Info, Notifications, QrCode, Star, TrendingDown, TrendingUp } from '@mui/icons-material';

export default function BadgeFromAppStoreVersion(props) {

    const getColor = () => {

        switch (props.version) {

            case 'PREORDER_READY_FOR_SALE':
            case 'READY_FOR_SALE':
                return "#388e3c"
            case 'WAITING_FOR_REVIEW':
            case 'WAITING_FOR_EXPORT_COMPLIANCE':
            case 'READY_FOR_REVIEW':
            case 'PROCESSING_FOR_APP_STORE':
            case 'IN_REVIEW':
            case 'ACCEPTED':
                return "#f57c00"
            case 'REMOVED_FROM_SALE':
            case 'REJECTED':
            case 'PENDING_CONTRACT':
            case 'PENDING_APPLE_RELEASE':
            case 'METADATA_REJECTED':
            case 'INVALID_BINARY':
            case 'DEVELOPER_REJECTED':
            case 'DEVELOPER_REMOVED_FROM_SALE':
                return "#d32f2f"
            case 'PENDING_DEVELOPER_RELEASE':
            case 'PREPARE_FOR_SUBMISSION':
                return "#ab47bc"
            case 'REPLACED_WITH_NEW_VERSION':
                return "#555555"

        }

    }

    const getText = () => {

        switch (props.version) {

            case 'PREORDER_READY_FOR_SALE':
            case 'READY_FOR_SALE':
                return "Available"

            case 'WAITING_FOR_REVIEW':
            case 'WAITING_FOR_EXPORT_COMPLIANCE':
            case 'READY_FOR_REVIEW':
            case 'PROCESSING_FOR_APP_STORE':
            case 'IN_REVIEW':
            case 'ACCEPTED':
                return "In review"

            case 'REMOVED_FROM_SALE':
            case 'REJECTED':
            case 'PENDING_CONTRACT':
            case 'PENDING_APPLE_RELEASE':
            case 'METADATA_REJECTED':
            case 'INVALID_BINARY':
            case 'DEVELOPER_REJECTED':
            case 'DEVELOPER_REMOVED_FROM_SALE':
                return "Rejected"

            case 'PENDING_DEVELOPER_RELEASE':
            case 'PREPARE_FOR_SUBMISSION':
                return "Waiting"

            case 'REPLACED_WITH_NEW_VERSION':
                return "Replaced"

        }

    }


    return (
        <Box sx={{backgroundColor:getColor(), px: 1, py: 0.1, borderRadius: 0.5, mr: 1, width: 125, textAlign:'center'}}>
            <Typography sx={{color:'white', fontSize: props.fontSize ? props.fontSize : 15, fontWeight:'bold'}}>
                {getText()}
            </Typography>
        </Box>
    )
}
