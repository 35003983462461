import * as React from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import ListProject from '../../requests/List.project'
import ListApplication from '../../requests/List.application'
import ListException from '../../requests/List.exception'
import FormDataBuilder from '../../utils/FormDataBuilder';
import { Box, List, ListItem, ListItemButton, Skeleton, Divider } from '@mui/material';
import { ArrowForward, ArrowForwardIos, ArrowLeft, ArrowRightOutlined, Cases, CheckCircle, Download, Error, Help, Info, Notifications, QrCode, Star, TrendingDown, TrendingUp } from '@mui/icons-material';

export default function BadgeProcessingBuild(props) {

    const getColor = () => {

        switch (props.version) {
            case 'VALID':
                if(props.build) {
                    if(props.build.relationships.appStoreVersion.data) {
                        if(props.currentBuildId == props.build.id) {
                            return "#ab47bc"
                        } else {
                            return "#555555"
                        }
                    }
                }


                return "#388e3c"
            case 'PROCESSING':
                return "#f57c00"

        }
        return "#d32f2f"



        switch (props.version) {

            case 'PREORDER_READY_FOR_SALE':
            case 'READY_FOR_SALE':
                return "#388e3c"
            case 'WAITING_FOR_REVIEW':
            case 'WAITING_FOR_EXPORT_COMPLIANCE':
            case 'READY_FOR_REVIEW':
            case 'PROCESSING_FOR_APP_STORE':
            case 'IN_REVIEW':
            case 'ACCEPTED':
            case 'REMOVED_FROM_SALE':
            case 'REJECTED':
            case 'PENDING_CONTRACT':
            case 'PENDING_APPLE_RELEASE':
            case 'METADATA_REJECTED':
            case 'INVALID_BINARY':
            case 'DEVELOPER_REJECTED':
            case 'DEVELOPER_REMOVED_FROM_SALE':
                return "#d32f2f"
            case 'PENDING_DEVELOPER_RELEASE':
            case 'PREPARE_FOR_SUBMISSION':
                return "#ab47bc"
            case 'REPLACED_WITH_NEW_VERSION':
                return "#555555"

        }

    }

    const getText = () => {
        switch (props.version) {
            case 'VALID':
                if(props.build) {
                    if(props.build.relationships.appStoreVersion.data) {

                        if(props.currentBuildId == props.build.id) {
                            return  "SELECT"
                        } else {
                            return  "UNAVAILABLE"
                        }
                    }
                }
                return "VALID"
            case 'PROCESSING':
                return "PROCESSING"

        }
        return props.version

        switch (props.version) {

            case 'PREORDER_READY_FOR_SALE':
            case 'READY_FOR_SALE':
                return "Available"

            case 'WAITING_FOR_REVIEW':
            case 'WAITING_FOR_EXPORT_COMPLIANCE':
            case 'READY_FOR_REVIEW':
            case 'PROCESSING_FOR_APP_STORE':
            case 'IN_REVIEW':
            case 'ACCEPTED':
                return "In review"

            case 'REMOVED_FROM_SALE':
            case 'REJECTED':
            case 'PENDING_CONTRACT':
            case 'PENDING_APPLE_RELEASE':
            case 'METADATA_REJECTED':
            case 'INVALID_BINARY':
            case 'DEVELOPER_REJECTED':
            case 'DEVELOPER_REMOVED_FROM_SALE':
                return "Rejected"

            case 'PENDING_DEVELOPER_RELEASE':
            case 'PREPARE_FOR_SUBMISSION':
                return "Waiting"

            case 'REPLACED_WITH_NEW_VERSION':
                return "Replaced"

        }

    }


    return (
        <Box sx={{ backgroundColor: getColor(), px: 1, py: 0.1, borderRadius: 0.5, mr: 1, width: 130, textAlign: 'center' }}>
            <Typography sx={{ color: 'white', fontSize: 15, fontWeight: 'bold' }}>
                {getText()}
            </Typography>
        </Box>
    )
}
