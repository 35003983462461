import Abstract from './Abstract'

class Delete extends Abstract {

    constructor(formDataBuilder) {
        super()
        this.method = 'POST'
        formDataBuilder.set("project_platform", "www")
        this.formData = formDataBuilder.get()
    }
}

export default Delete