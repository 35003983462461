import * as React from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import ListProject from '../requests/List.project'
import FormDataBuilder from '../utils/FormDataBuilder';
import { List, ListItem, Divider } from '@mui/material';
import BigSkeleton from '../components/Skeleton';
import StatusContainer from '../components/StatusContainer';
export default function Content() {

  const [projects, setProjects] = React.useState([])

  const getProjects = async () => {
    let result = await new ListProject(
      new FormDataBuilder()
    ).emit()
    if (result.error) {
      //TODO error
    } else {
      setProjects(result.data.filter(project => {
        return project.test === "0"
      }).sort((a, b) => {
        if (a.name.toUpperCase() > b.name.toUpperCase()) {
          return 1
        }
        return -1
      }))

    }
  }

  React.useEffect(() => {
    getProjects()
  }, [])

  return (
    projects.length === 0 ?
      <BigSkeleton />
      :
      <Paper sx={{ maxWidth: 836, margin: 'auto', overflow: 'hidden' }}>
        <List>
          <ListItem sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', px: 2, py: 1 }} >
            <Typography color="text.primary" sx={{ width: 200 }}  >
              Project
            </Typography>
            <Typography color="text.primary" sx={{  width: 100, alignItems: 'center', alignContent: 'center', justifyContent: 'center', alignSelf: 'center', display: 'flex' }}>
              PlayStore
            </Typography>
            <Typography color="text.primary" sx={{ width: 100, alignItems: 'center', alignContent: 'center', justifyContent: 'center', alignSelf: 'center', display: 'flex' }}>
              AppStore
            </Typography>
            <Typography color="text.primary" sx={{ width: 100, textAlign: 'center' }}>
              Release type
            </Typography>
            <Typography color="text.primary" sx={{ width: 50, textAlign: 'center' }}>
              Status
            </Typography>
            <Typography color="text.primary" sx={{ width: 50, textAlign: 'center' }}>
            </Typography>
          </ListItem>
          <Divider />
          {
            projects.map((project, index) => {
              return (
                <StatusContainer project={project} />
              )
            })
          }
        </List>
      </Paper>
  )
}
