import * as React from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { Box, Divider, Card, Tabs, Tab, Button, List, ListItem, ListItemButton, TextField, IconButton } from '@mui/material';
import { useParams, useHistory } from 'react-router-dom';
import BigSkeleton from '../components/Skeleton';
import BadgeFromAppStoreVersion from '../components/appstore/BadgeFromAppStoreVersion';
import BadgeExpiryBuild from '../components/appstore/BadgeExpiryBuild';
import BadgeAudienceBuild from '../components/appstore/BadgeAudienceBuild';
import BadgeProcessingBuild from '../components/appstore/BadgeProcessingBuild';
import Moment from 'moment';
import { Check, Close, Refresh, Save } from '@mui/icons-material';
export default function Release(props) {

    const params = useParams()
    const navigate = useHistory()

    const load = async () => {
        setLoading(true)
        let resultAppStoreToken = await fetch("https://mobilekey.otipass.com/appstore", {
            headers: {
                Authorization: 'Basic YWRtaW46b3RpcGFzcysrYXV0aA==',
            }
        })
        let resultAppStoreTokenJson = await resultAppStoreToken.json()
        setAppleToken(resultAppStoreTokenJson.token)
        await getRelease(resultAppStoreTokenJson.token, params.idrelease)
        setLoading(false)
    }

    const getRelease = async (token, idRelease) => {
        console.log("Get releases")
        let formData = new FormData()
        formData.append("url", "https://api.appstoreconnect.apple.com/v1/appStoreVersions/" + idRelease)
        formData.append("token", token)
        let result = await fetch("https://supervision.mobile.otipass.net/appstore/exec.php", { method: 'POST', body: formData })
        let resultJSON = await result.json()
        if (resultJSON.data) {
            resultJSON.data.attributes.localisations = await getVersionLocalizations(token, resultJSON.data.id)
            resultJSON.data.attributes.build = await getBuild(token, resultJSON.data.id)
            await getBuilds(token)


            setRelease(resultJSON.data)
        }
    }

    const getBuilds = async (token) => {
        console.log("Get all builds ")
        setBuilds([])
        let formData = new FormData()
        formData.append("url", "https://api.appstoreconnect.apple.com/v1/builds?limit=200&filter[app]=" + params.id + "&filter[expired]=false&sort=-uploadedDate&include=appStoreVersion")
        formData.append("token", token)
        let result = await fetch("https://supervision.mobile.otipass.net/appstore/exec.php", { method: 'POST', body: formData })
        let resultJSON = await result.json()
        if (resultJSON.data) {
            console.log(resultJSON)
            setBuilds(resultJSON.data.splice(0, 3))
        }
    }

    const getBuild = async (token, idAppVersion) => {
        console.log("App version localizations " + idAppVersion)
        let formData = new FormData()
        formData.append("url", "https://api.appstoreconnect.apple.com/v1/appStoreVersions/" + idAppVersion + "/build")
        formData.append("token", token)
        let result = await fetch("https://supervision.mobile.otipass.net/appstore/exec.php", { method: 'POST', body: formData })
        let resultJSON = await result.json()
        if (resultJSON.data) {
            return resultJSON.data
        }
    }

    const getVersionLocalizations = async (token, idAppVersion) => {
        console.log("App version localizations " + idAppVersion)
        let formData = new FormData()
        formData.append("url", "https://api.appstoreconnect.apple.com/v1/appStoreVersions/" + idAppVersion + "/appStoreVersionLocalizations")
        formData.append("token", token)
        let result = await fetch("https://supervision.mobile.otipass.net/appstore/exec.php", { method: 'POST', body: formData })
        let resultJSON = await result.json()
        if (resultJSON.data) {
            if(localStorage.getItem("showScreenshot") == "true") {
                for (let index = 0; index < resultJSON.data.length; index++) {
                    let versionLocalisation = resultJSON.data[index]
                    resultJSON.data[index].screenshots = await getScreenshotSet(token, versionLocalisation.id) 
                }
            }
            let currentWhatsNew = {}
            let defaultWhatsNew = {}
            resultJSON.data.forEach(localisation => {
                if (localisation.attributes.whatsNew) {
                    currentWhatsNew[localisation.attributes.locale] = ""
                    defaultWhatsNew[localisation.attributes.locale] = localisation.attributes.whatsNew
                } else {
                    currentWhatsNew[localisation.attributes.locale] = ""
                    defaultWhatsNew[localisation.attributes.locale] = ""
                }
            })
            setWhatsNew(currentWhatsNew)
            setDefaultNew(defaultWhatsNew)
            return resultJSON.data
        }
    }

    const getScreenshotSet = async (token, idAppStoreVersionLocalisation) => {
        console.log("Get screen shot set with uuid " + idAppStoreVersionLocalisation)
        let formData = new FormData()
        formData.append("url", "https://api.appstoreconnect.apple.com/v1/appStoreVersionLocalizations/" + idAppStoreVersionLocalisation + "/appScreenshotSets")
        formData.append("token", token)
        let result = await fetch("https://supervision.mobile.otipass.net/appstore/exec.php", { method: 'POST', body: formData })
        let resultJSON = await result.json()
        if (resultJSON.data) {
            let screenshots = []
            for (let index = 0; index < resultJSON.data.length; index++) {
                let screenShotSet = resultJSON.data[index]
                screenshots.push(await getAppScreenShot(token, screenShotSet.id))
            }
            return screenshots
        } else {
            return []
        }
    }

    const getAppScreenShot = async (token, idScreenShotSet) => {
        console.log("Get screen shot with uuid " + idScreenShotSet)
        let formData = new FormData()
        formData.append("url", "https://api.appstoreconnect.apple.com/v1/appScreenshotSets/" + idScreenShotSet + "/appScreenshots")
        formData.append("token", token)
        let result = await fetch("https://supervision.mobile.otipass.net/appstore/exec.php", { method: 'POST', body: formData })
        let resultJSON = await result.json()
        if (resultJSON.data) {
            return resultJSON
        } else {
            return []
        }
    }

    const updateVersion = async (idVersion) => {
        setLoading(true)
        let formData = new FormData()
        formData.append("url", "https://api.appstoreconnect.apple.com/v1/appStoreVersions/" + params.idrelease)
        formData.append("token", appleToken)
        formData.append("data", JSON.stringify({
            "data": {
                "id": params.idrelease,
                "type": "appStoreVersions",
                "attributes": {
                    "versionString": idVersion
                }
            }
        }))
        await fetch("https://supervision.mobile.otipass.net/appstore/exec_patch.php", { method: 'POST', body: formData })
        await getRelease(appleToken,params.idrelease)
        setLoading(false)
    }

    const addBuildInVersion = async (idBuild) => {
        setLoading(true)
        await usesNonExemptEncryption(idBuild)
        let formData = new FormData()
        formData.append("url", "https://api.appstoreconnect.apple.com/v1/appStoreVersions/" + params.idrelease)
        formData.append("token", appleToken)
        formData.append("data", JSON.stringify({
            "data": {
                "id": params.idrelease,
                "type": "appStoreVersions",
                "relationships": {
                    "build": {
                        "data": {
                            "type": "builds",
                            "id": idBuild,
                        }
                    }
                }
            }
        }))
        await fetch("https://supervision.mobile.otipass.net/appstore/exec_patch.php", { method: 'POST', body: formData })
        await getRelease(appleToken,params.idrelease)
        setLoading(false)
    }


    const usesNonExemptEncryption = async (idBuild) => {
        let formDataEncryption = new FormData()
        formDataEncryption.append("url", "https://api.appstoreconnect.apple.com/v1/builds/" + idBuild)
        formDataEncryption.append("token", appleToken)
        formDataEncryption.append("data", JSON.stringify({
            "data": {
                "attributes": {
                    "usesNonExemptEncryption": false
                },
                "type": "builds",
                "id": idBuild,
            }
        }))
        await fetch("https://supervision.mobile.otipass.net/appstore/exec_patch.php", { method: 'POST', body: formDataEncryption })

    }


    const updateBuildVersion = async (idBuild) => {
        setLoading(true)
        await usesNonExemptEncryption(idBuild)
        let formData = new FormData()
        formData.append("url", "https://api.appstoreconnect.apple.com/v1/appStoreVersions/" + params.idrelease + "/relationships/build")
        formData.append("token", appleToken)
        formData.append("data", JSON.stringify({
            "data": {
                "type": "builds",
                "id": idBuild,
            }
        }))
        await fetch("https://supervision.mobile.otipass.net/appstore/exec_patch.php", { method: 'POST', body: formData })
        await getRelease(appleToken,params.idrelease)
        setLoading(false)
    }

    const updateNews = async (idLocalisation, news) => {
        setLoading(true)
        let formData = new FormData()
        formData.append("url", "https://api.appstoreconnect.apple.com/v1/appStoreVersionLocalizations/" + idLocalisation)
        formData.append("token", appleToken)
        formData.append("data", JSON.stringify({
            "data": {
                "id": idLocalisation,
                "type": "appStoreVersionLocalizations",
                "attributes": {
                    "whatsNew": news,
                }
            }

        }))
        await fetch("https://supervision.mobile.otipass.net/appstore/exec_patch.php", { method: 'POST', body: formData })
        await getRelease(appleToken,params.idrelease)
        setLoading(false)
    }

    const submitSubmission = async () => {
        setLoading(true)
        let formData = new FormData()
        formData.append("url", "https://api.appstoreconnect.apple.com/v1/appStoreVersionSubmissions")
        formData.append("token", appleToken)
        formData.append("data", JSON.stringify({
            data: {
                type: "appStoreVersionSubmissions",
                relationships: {
                    appStoreVersion: {
                        data: {
                            id: params.idrelease,
                            type: "appStoreVersions"
                        }
                    }
                }
            }
        }),
        )
        await fetch("https://supervision.mobile.otipass.net/appstore/exec_post.php", { method: 'POST', body: formData })
        await getRelease(appleToken,params.idrelease)
        navigate.push('/appstore/' + params.id)
    }

    const deleteVersion = async () => {
        setLoading(true)
        let formData = new FormData()
        formData.append("url", "https://api.appstoreconnect.apple.com/v1/appStoreVersions/" + params.idrelease)
        formData.append("token", appleToken)
        await fetch("https://supervision.mobile.otipass.net/appstore/exec_delete.php", { method: 'POST', body: formData })
    }

    const [appleToken, setAppleToken] = React.useState("")
    const [whatsNew, setWhatsNew] = React.useState({})
    const [defaultNew, setDefaultNew] = React.useState({})
    const [tab, setTab] = React.useState(0)
    const [builds, setBuilds] = React.useState(false)
    const [loading, setLoading] = React.useState(true)
    const [showMore, setShowMore] = React.useState(false)
    const [release, setRelease] = React.useState({})

    React.useEffect(() => {
        load()
    }, [])
    return (
        <>
            {
                loading ?
                    <BigSkeleton />
                    :
                    <Paper sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden', px: 3, py: 2 }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Box>
                                <BadgeFromAppStoreVersion fontSize={16} version={release.attributes.appStoreState} />
                                <Typography sx={{ fontSize: 24, mt: 1 }} >
                                    {release.attributes.versionString}
                                </Typography>
                                <Typography color='text.secondary' onClick={() => { navigate.push('/appstore/' + params.id) }} >
                                    {params.id}
                                </Typography>
                                <Typography color='text.secondary' sx={{ mb: 1 }} >
                                    {params.idrelease}
                                </Typography>
                            </Box>
                            {
                                release.attributes.build ?
                                    <Card sx={{ p: 1, display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', alignSelf: 'center', alignContent: 'center', width: 'min-content' }} elevation={2}>
                                        <img src={release.attributes.build.attributes.iconAssetToken.templateUrl.replace("{w}", release.attributes.build.attributes.iconAssetToken.width).replace("{h}", release.attributes.build.attributes.iconAssetToken.height).replace("{f}", "png")} style={{ height: 'auto', width: 70, borderRadius: 15 }} />
                                    </Card>
                                    :
                                    <Card sx={{ p: 1, display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', alignSelf: 'center', alignContent: 'center', width: 'min-content' }} elevation={2}>
                                        <Box style={{ height: 70, width: 70, backgroundColor: '#BBBBBB', borderRadius: 15 }} />
                                    </Card>
                            }
                        </Box>
                        <Divider sx={{ mb: 2, mt: 1 }} />
                            {
                                release.attributes.appStoreState == "PENDING_DEVELOPER_RELEASE" || release.attributes.appStoreState == "PREPARE_FOR_SUBMISSION" ?
                                    <Card sx={{ p: 2, my: 2 }}>
                                        <Typography sx={{ fontSize: 22 }} >
                                            {"Action"}
                                        </Typography>
                                        <Divider sx={{ mt: 1, mb: 2 }} />
                                            <Button disabled={release.attributes.build == null} size='small' color='warning' variant='contained' onClick={() => { submitSubmission() }} >
                                                <Typography  >
                                                    {"Submit"}
                                                </Typography>
                                            </Button>
                                        {
                                            localStorage.getItem("last") != release.attributes.versionString && localStorage.getItem("last") ?
                                            <Button size='small' color='success' sx={{ml: 1}} variant='contained' onClick={() => { updateVersion(localStorage.getItem("last")) }} >
                                                <Typography  >
                                                    {"Update version (" + localStorage.getItem("last") + ")"}
                                                </Typography>
                                            </Button> : null

                                        }
                                    </Card> : null
                            }
                        <Box sx={{ mt: 2 }}>
                            {

                                <Card sx={{ p: 2, my: 2 }}>
                                    {
                                        release.attributes.build ?
                                            <>

                                                <Typography sx={{ fontSize: 22 }} >
                                                    {"Build n°" + release.attributes.build.attributes.version}
                                                </Typography>
                                                <Typography color='text.secondary' sx={{ fontSize: 14 }} >
                                                    {release.attributes.build.id}
                                                </Typography>
                                            </> :
                                            <>
                                                <Typography sx={{ fontSize: 22 }} >
                                                    {"Select build"}
                                                </Typography>
                                            </>
                                    }
                                    {
                                        release.attributes.appStoreState != "READY_FOR_SALE" && release.attributes.appStoreState != "REPLACED_WITH_NEW_VERSION" ?
                                            <>
                                                <Divider sx={{ mt: 2, mb: 2 }} />
                                                <List>
                                                    {
                                                        builds.map((build, index) => {
                                                            let isSelect = false
                                                            if (release.attributes.build) {
                                                                if (build.id == release.attributes.build.id) {
                                                                    isSelect = true
                                                                }
                                                            }
                                                            return (
                                                                <ListItem sx={{ p: 0 }}>
                                                                    <ListItemButton disabled={build.attributes.processingState != "VALID" || build.relationships.appStoreVersion.data} onClick={() => { release.attributes.build ? updateBuildVersion(build.id) : addBuildInVersion(build.id) }} sx={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: 100 }}>
                                                                            <img src={build.attributes.iconAssetToken.templateUrl.replace("{w}", build.attributes.iconAssetToken.width).replace("{h}", build.attributes.iconAssetToken.height).replace("{f}", "png")} style={{ height: 'auto', width: 30, borderRadius: 5 }} />
                                                                            <Typography color='text.secondary' sx={{ ml: 2 }}  >
                                                                                {build.attributes.version}
                                                                            </Typography>
                                                                        </Box>
                                                                        <Typography color='text.secondary' sx={{ width: 325, fontWeight: isSelect ? 'bold' : 'normal' }} >
                                                                            {build.id}
                                                                        </Typography>
                                                                        <BadgeProcessingBuild currentBuildId={release.attributes.build ? release.attributes.build.id : ""} build={build} version={build.attributes.processingState} />
                                                                        <Typography color='text.secondary'  >
                                                                            {
                                                                                Moment(build.attributes.uploadedDate).format("YYYY-MM-DD")
                                                                            }
                                                                        </Typography>
                                                                    </ListItemButton>
                                                                </ListItem>
                                                            )
                                                        })
                                                    }
                                                </List>
                                            </> : null
                                    }

                                </Card>
                            }
                            {
                                release.attributes.localisations.map(localisation => {
                                    return (
                                        <Card sx={{ p: 2, my: 2 }}>
                                            <Box>
                                                <Box sx={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center' }}>
                                                    <Typography sx={{ fontSize: 22 }} >
                                                        {"Description " + (release.attributes.localisations.length > 1 ? localisation.attributes.locale : "")}
                                                    </Typography>
                                                </Box>
                                                <Typography color='text.secondary' sx={{ mb: 1, fontSize: 14 }} >
                                                    {localisation.id}
                                                </Typography>
                                            </Box>
                                            <Divider sx={{ mt: 2, mb: 1 }} />
                                            {
                                                release.attributes.appStoreState == "PENDING_DEVELOPER_RELEASE" || release.attributes.appStoreState == "PREPARE_FOR_SUBMISSION" ?
                                                    <Box sx={{ my: 2, display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center' }}>
                                                        <TextField placeholder={defaultNew[localisation.attributes.locale]} multiline fullWidth onChange={(event, value) => { whatsNew[localisation.attributes.locale] = event.target.value; setWhatsNew(whatsNew) }} />
                                                        <IconButton onClick={() => { updateNews(localisation.id, whatsNew[localisation.attributes.locale]) }} >
                                                            <Save />
                                                        </IconButton>
                                                    </Box>
                                                    :
                                                    localisation.attributes.whatsNew ?
                                                        <> <Typography sx={{ whiteSpace: 'break-spaces' }} >
                                                            {localisation.attributes.whatsNew}
                                                        </Typography>
                                                            <Divider sx={{ my: 1 }} /> </> : null
                                            }
                                            <Typography sx={{ whiteSpace: 'break-spaces' }} >
                                                {showMore ? localisation.attributes.description : (localisation.attributes.description.slice(0, 300) + " ...")}
                                            </Typography>
                                            <Typography sx={{ cursor: 'pointer', mt: 2, fontSize: 14, fontWeight: 'bold' }} onClick={() => { setShowMore(!showMore) }}>
                                                {showMore ? "Dismiss" : "Show full description"}
                                            </Typography>
                                            <Divider sx={{ my: 1 }} />
                                            <Typography sx={{ whiteSpace: 'break-spaces' }}>
                                                {"Support page : " + localisation.attributes.supportUrl}
                                            </Typography>
                                            <Typography sx={{ whiteSpace: 'break-spaces' }} >
                                                {"Marketing page : " + localisation.attributes.marketingUrl}
                                            </Typography>
                                            <Divider sx={{ my: 1 }} />
                                            <Typography color='text.secondary' sx={{ whiteSpace: 'break-spaces' }} >
                                                {localisation.attributes.keywords}
                                            </Typography>
                                            <Divider sx={{ my: 1 }} />
                                            {
                                                localisation.screenshots ?
                                                    <>
                                                        {
                                                            localisation.screenshots[tab].data.slice(0, 6).map((screen, index) => {
                                                                let url = screen.attributes.imageAsset.templateUrl.replace("{w}", screen.attributes.imageAsset.width).replace("{h}", screen.attributes.imageAsset.height).replace("{f}", "png")
                                                                return <a key={tab + "-" + index} style={{ marginRight: 2, marginLeft: 2 }} href={url} target="_blank"><img src={url} style={{ height: 'auto', maxWidth: 420, width: 800 / localisation.screenshots[tab].data.slice(0, 6).length }} /></a>
                                                            })
                                                        }
                                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center', justifyContent: 'space-between' }}>
                                                            <Button disabled={tab == 0} onClick={() => { setTab(tab - 1) }}>
                                                                Previous
                                                            </Button>
                                                            <Button disabled={tab + 1 == localisation.screenshots.length} onClick={() => { setTab(tab + 1) }}>
                                                                Next
                                                            </Button>
                                                        </Box>
                                                    </> : null
                                            }
                                        </Card>
                                    )
                                })
                            }
                        </Box>
                    </Paper>
            }
        </>
    )
}