import * as React from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import DetailAnnuaire from '../requests/Detail.annuaire'
import ListProject from '../requests/List.project'
import ListException from '../requests/List.exception'
import FormDataBuilder from '../utils/FormDataBuilder';
import { Box, Divider, MenuItem, InputLabel, FormControl, Select, List, IconButton, TextField, ListItem, Skeleton, Rating } from '@mui/material';
import { CloudUpload, BurstMode, Map, Route, AspectRatio, Info, PlayCircle, Calculate, RocketLaunch, Send, SettingsSystemDaydream, Shop, Upload, QrCodeScanner, Flip, CropFree, ShoppingCart, Timer, Public, Flag, Start, LinearScale, Web, Star, Category, Gavel, Translate, Favorite, Restore, Image, Search, Call, StickyNote2, MapRounded, Event, EventNote, HelpCenter } from '@mui/icons-material';

import { useParams } from 'react-router-dom';


export default function Archives() {

    const params = useParams()
    const [projects, setProjects] = React.useState([])
    const [selectProject, setSelectProject] = React.useState("")
    const [serial, setSerial] = React.useState("")
    const [loading, setLoading] = React.useState(false)
    const [stats, setStats] = React.useState([])
    const [device, setDevice] = React.useState({ serial: "" })

    const fetchAnnuaire = async (project, pSerial) => {
        setLoading(true)

        let result = await new DetailAnnuaire(
            new FormDataBuilder()
                .set('serial', pSerial)
                .set('application_key', "moon_" + project)
        ).emit()
        if (result.error == false) {
            setStats(result.data.statisticals.reverse().filter(stat => {
                return ["application-device-isDevice", "tutorial-totalhobbies", "application-device-api", "application-device-manufacturer", "application-device-modelName", "application-device-modelId", "application-device-os"].includes(stat.type) == false
            }))
            setDevice(result.data)
        } else {
            setStats([])
            setDevice({ serial: "" })
        }
        setLoading(false)

    }

    const getProject = async () => {
        let resultProject = await new ListProject(
            new FormDataBuilder()
        ).emit()
        if (resultProject.error) {
            return
        }
        setProjects(resultProject.data)

    }

    React.useEffect(() => {
        if (params.serial && params.id) {
            fetchAnnuaire(params.id, params.serial)
        } else {
            getProject()
        }
    }, [])

    return (
        <>

            <Paper sx={{ maxWidth: 936, cursor: 'pointer', margin: 'auto', overflow: 'hidden', px: 3, py: 2 }}>
                <Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center' }}>
                        <Restore sx={{ mr: 1, fontSize: 30 }} htmlColor='rgba(0, 0, 0, 0.8)' />
                        <Typography sx={{ fontSize: 24 }}>
                            {"History"}
                        </Typography>
                    </Box>
                </Box>

            </Paper>
            {
                params.serial && params.id ? null :
                    <Paper sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden', mt: 2 }}>
                        <Box sx={{ p: 2, display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center', justifyContent: 'space-between' }}>
                            <FormControl sx={{ flex: 1 }}>
                                <Select
                                    labelId="project-select"
                                    id="project-select"
                                    placeholder='Project'
                                    value={selectProject}
                                    onChange={(event) => {
                                        setSelectProject(event.target.value)
                                    }}
                                >
                                    {
                                        projects.map((project, index) => {
                                            return <MenuItem key={index} value={project.access}>{project.name}</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>
                            <TextField sx={{ mx: 2, flex: 1 }} placeholder='Serial number' value={serial} onChange={(event, value) => { setSerial(event.target.value) }} />
                            <IconButton onClick={() => { fetchAnnuaire(selectProject, serial) }} sx={{ borderRadius: 1 }} disabled={loading || selectProject.length == 0 || serial.length == 0} >
                                <Send color={loading ? 'warning' : selectProject.length == 0 || serial.length == 0 ? "disabled" : 'primary'} fontSize='large' />
                            </IconButton>
                        </Box>
                    </Paper>
            }
            {
                device.serial.length > 0 ?
                    <Paper sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden', mt: 2 }}>
                        <Box sx={{ p: 2 }}>
                            <Typography >
                                {device.serial}
                            </Typography>
                            <Typography color="text.secondary">
                                {device.model}
                            </Typography>
                        </Box>
                    </Paper> : null
            }
            {
                device.serial.length > 0 ?
                    <Paper sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden', mt: 2 }}>
                        <List>
                            {
                                stats.map((stat, index) => {
                                    return (
                                        <>
                                            <ListItem key={index} sx={{ px: 0, display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center', justifyContent: 'space-between' }}>
                                                <Typography sx={{ flex: 1, alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                                                    {TranslateStatsActions[stat.type] ? TranslateStatsActions[stat.type].icon : stat.value}
                                                </Typography>
                                                <Typography sx={{ flex: 6 }}>
                                                    {TranslateStatsActions[stat.type] ? TranslateStatsActions[stat.type].name.replace("%value%", stat.value.capitalize()) : stat.type}
                                                </Typography>
                                                <Typography sx={{ flex: 2 }}>
                                                    {stat.date}
                                                </Typography>
                                            </ListItem>
                                            {
                                                stat.type == "version" && <Divider />
                                            }

                                        </>
                                    )
                                })
                            }
                            {
                                stats.length == 0 ?
                                    <ListItem sx={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Typography color='text.secondary'  >
                                            {"No action on this project with this device"}
                                        </Typography>
                                    </ListItem> : null
                            }
                        </List>
                    </Paper> : null
            }
        </>
    )
}


const TranslateStatsActions = {
    "marketing": {
        "name": "Displaying marketing card",
        "icon": <StickyNote2 fontSize='large' htmlColor='#7D4CDB' />,
        "reference": "",
        "value": ""
    },
    "shop-navigate": {
        "name": "Visit to the shop",
        "icon": <Shop fontSize='large' htmlColor='#4fc3f7' />,
        "reference": "",
        "value": ""
    },
    "discover-event": {
        "name": "Visit event list",
        "icon": <Event fontSize='large' htmlColor='#81c784' />,
        "reference": "",
        "value": ""
    },
    "discover-information": {
        "name": "Display discover information",
        "icon": <HelpCenter fontSize='large' htmlColor='#81c784' />,
        "reference": "",
        "value": ""
    },
    "event-show": {
        "name": "Show event < %value% >",
        "icon": <EventNote fontSize='large' htmlColor='#81c784' />,
        "reference": "",
        "value": ""
    },
    "service-showmap": {
        "name": "Displaying activity map < %value% > ",
        "icon": <Map fontSize='large' htmlColor='#81c784' />,
        "reference": "",
        "value": ""
    },
    "shop-postassociate": {
        "name": "Associate PASS after buying",
        "icon": <CropFree fontSize='large' htmlColor='#4fc3f7' />,
        "reference": "",
        "value": ""
    },
    "shop-select-product": {
        "name": "Add cart < %value% > ",
        "icon": <ShoppingCart fontSize='large' htmlColor='#4fc3f7' />,
        "reference": "",
        "value": ""
    },
    "application-showcgv": {
        "name": "Visit CGV page ",
        "icon": <Gavel fontSize='large' htmlColor='#4fc3f7' />,
        "reference": "",
        "value": ""
    },
    "associate-action": {
        "name": "Associate PASS",
        "icon": <QrCodeScanner fontSize='large' htmlColor='#ffb74d' />,
        "reference": "",
        "value": ""
    },
    "application-time-initialisation": {
        "name": "Client waiting %value% for initialisation",
        "icon": <Timer fontSize='large' htmlColor='#e57373' />,
        "reference": "",
        "value": ""
    },
    "application-first": {
        "name": "First time start",
        "icon": <Flag fontSize='large' htmlColor='#e57373' />,
        "reference": "",
        "value": ""
    },
    "application-start": {
        "name": "Start application",
        "icon": <PlayCircle fontSize='large' htmlColor='#e57373' />,
        "reference": "",
        "value": ""
    },
    "version": {
        "name": "Application version is %value%",
        "icon": <LinearScale fontSize='large' htmlColor='#e57373' />,
        "reference": "",
        "value": ""
    },
    "opinion": {
        "name": "Client want < %value% > to set opinion",
        "icon": <Star fontSize='large' htmlColor='#e57373' />,
        "reference": "",
        "value": ""
    },
    "opinion-action": {
        "name": "Client want < %value% > to set opinion",
        "icon": <Star fontSize='large' htmlColor='#e57373' />,
        "reference": "",
        "value": ""
    },
    "service-showwebsite": {
        "name": "Visite website from < %value% >",
        "icon": <Web fontSize='large' htmlColor='#81c784' />,
        "reference": "",
        "value": ""
    },
    "application-showinformation": {
        "name": "Visit about page",
        "icon": <Info fontSize='large' htmlColor='#e57373' />,
        "reference": "",
        "value": ""
    },
    "tutorial-hobbys": {
        "name": "Select < %value% > on favorite categories",
        "icon": <Category fontSize='large' htmlColor='#ffb74d' />,
        "reference": "",
        "value": ""
    },
    "device-language": {
        "name": "Language default is %value%",
        "icon": <Public fontSize='large' htmlColor='#e57373' />,
        "reference": "",
        "value": ""
    },
    "application-language": {
        "name": "Client set language %value%",
        "icon": <Translate fontSize='large' htmlColor='#e57373' />,
        "reference": "",
        "value": ""
    },
    "service-showphone": {
        "name": "Get phone from < %value% >",
        "icon": <Public fontSize='large' htmlColor='#81c784' />,
        "reference": "",
        "value": ""
    },
    "service-showroute": {
        "name": "Get itinary from < %value% >",
        "icon": <Route fontSize='large' htmlColor='#81c784' />,
        "reference": "",
        "value": ""
    },
    "service-favorites": {
        "name": "Set favorites < %value% >",
        "icon": <Favorite fontSize='large' htmlColor='#81c784' />,
        "reference": "",
        "value": ""
    },
    "shop-addcart": {
        "name": "Add cart < %value% >",
        "icon": <ShoppingCart fontSize='large' htmlColor='#4fc3f7' />,
        "reference": "",
        "value": ""
    },
    "service-show": {
        "name": "Show service < %value% >",
        "icon": <Image fontSize='large' htmlColor='#81c784' />,
        "reference": "",
        "value": ""
    },
    "discover-map": {
        "name": "Displaying discover map",
        "icon": <MapRounded fontSize='large' htmlColor='#7D4CDB' />,
        "reference": "",
        "value": ""
    },
    "calculator": {
        "name": "Show calculator",
        "icon": <Calculate fontSize='large' htmlColor='#c72c48' />,
        "reference": "",
        "value": ""
    },
    "discover-categories": {
        "name": "Open cartegories < %value% >",
        "icon": <BurstMode fontSize='large' htmlColor='#81c784' />,
        "reference": "",
        "value": ""
    },
    "tutorial-action": {
        "name": "Action on tutorial < %value% >",
        "icon": <AspectRatio fontSize='large' htmlColor='#ffb74d' />,
        "reference": "",
        "value": ""
    },
    "service-search-v2": {
        "name": "Search value < %value% >",
        "icon": <Search fontSize='large' htmlColor='#81c784' />,
        "reference": "",
        "value": ""
    },
    "application-contact": {
        "name": "Navigation in contact",
        "icon": <Call fontSize='large' htmlColor='#81c784' />,
        "reference": "",
        "value": ""
    }
}