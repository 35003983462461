import Constants from "../constants.json"

class GetFile  {

    constructor(formDataBuilder) {
        this.baseUrl = Constants.baseUrl
        this.method = 'POST'
        formDataBuilder.set('integration_action','list_archive')
        this.formData = formDataBuilder.get()
    }


    async emit() {
        let fetchRes = await fetch(this.baseUrl, {
            method: this.method,
            body: this.formData
        })
        try {
            let jsonRes = await fetchRes.json()
            return jsonRes
        } catch (error) {
            return {error: true, message: 'Internal error'}
        }
    }
}

export default GetFile