import * as React from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import FormDataBuilder from '../utils/FormDataBuilder';
import { Box, TextField, AccordionSummary, Accordion, Checkbox, Modal, Icon, IconButton } from '@mui/material';
import { CheckCircle, CopyAll, Download, Error, Help, Info, Inventory, Language, Notifications, QrCode, Settings } from '@mui/icons-material';
import ListBuild from '../requests/List.build'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import QRCode from "react-qr-code"

export default function Archives() {

    const [minVersion, setMinVersion] = React.useState(localStorage.getItem("min") ? localStorage.getItem("min") : "0.0.0")
    const [lastVersion, setLastVersion] = React.useState(localStorage.getItem("last") ? localStorage.getItem("last") : "0.0.0")
    const [archives, setArchives] = React.useState([{ value: [] }])
    const [showScreenshot, setShowScreenshot] = React.useState(localStorage.getItem("showScreenshot") ? localStorage.getItem("showScreenshot") : "true")

    const getArchives = async () => {
        let result = await new ListBuild(
            new FormDataBuilder()
        ).emit()
        setArchives(result.data)
    }

    React.useEffect(() => {
        getArchives()
    }, [])

    return (
        <>
            <Paper sx={{ maxWidth: 936, cursor: 'pointer', margin: 'auto', overflow: 'hidden', px: 3, py: 2 }}>
                <Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center' }}>
                        <Settings sx={{ mr: 1, fontSize: 30 }} htmlColor='rgba(0, 0, 0, 0.8)' />
                        <Typography sx={{ fontSize: 24 }}>
                            {"Settings"}
                        </Typography>
                    </Box>
                </Box>

            </Paper>
            <Paper sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden', mt: 2 }}>
                <Box sx={{ p: 2 }}>
                    <Typography sx={{ mb: 1 }} >
                        {"Minimum version"}
                    </Typography>
                    <TextField value={minVersion} onChange={(event, value) => { setMinVersion(event.target.value); localStorage.setItem("min", event.target.value) }} />
                </Box>
                <Box sx={{ p: 2 }}>
                    <Typography sx={{ mb: 1 }} >
                        {"Last version"}
                    </Typography>
                    <TextField value={lastVersion} onChange={(event, value) => { setLastVersion(event.target.value); localStorage.setItem("last", event.target.value) }} />
                </Box>
                <Box sx={{ p: 2 }}>
                    <Typography sx={{ mb: 1 }} >
                        {"Show screenshot"}
                    </Typography>
                    <Checkbox onChange={(event) => {
                        setShowScreenshot(event.target.checked ? "true" : "false")
                        localStorage.setItem("showScreenshot", event.target.checked ? "true" : "false")
                    }} checked={showScreenshot == "true" ? true : false} defaultChecked />
                </Box>
            </Paper>
        </>

    )
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 360,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};