import * as React from 'react';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PeopleIcon from '@mui/icons-material/People';
import DnsRoundedIcon from '@mui/icons-material/DnsRounded';
import { Link } from 'react-router-dom'
import { Apps, BarChart, Build, Contactless, Flip, Inventory, LibraryBooks, Logout, Mail, Message, QrCode, Restore, Scale, Scanner, Settings, ShowChart } from '@mui/icons-material';

const categories = [
  {
    id: 'General',
    children: [
      { id: 'Dashboard', screen: '', icon: <Apps /> },
      { id: 'Controller', screen: 'controller', icon: <Flip /> },
    ],
  },
  {
    id: 'Statistiques',
    children: [
      { id: 'Big data', screen: 'statistical',icon: <BarChart /> },
    ],
  },
  {
    id: 'Support',
    children: [
      { id: 'History', screen: 'history',icon: <Restore /> },
    ],
  },
  {
    id: 'Settings',
    children: [
      { id: 'Configuration', screen: 'configuration',icon: <Settings /> },
      { id: 'Disconnect', screen: 'disconnect',icon: <Logout /> },
    ],
  },
];

const item = {
  py: '2px',
  px: 3,
  color: 'rgba(255, 255, 255, 0.7)',
  '&:hover, &:focus': {
    bgcolor: 'rgba(255, 255, 255, 0.08)',
  },
};

const itemCategory = {
  boxShadow: '0 -1px 0 rgb(255,255,255,0.1) inset',
  py: 1.5,
  px: 3,
};

export default function Navigator(props) {


  const [content, setContent] = React.useState(categories[0].children[0].id)

  return (
    <Drawer variant="permanent" {...props}>
      <List disablePadding>
        <ListItem sx={{ ...item, ...itemCategory, fontSize: 22, color: '#fff' }}>
          Otipass Mobile
        </ListItem>
        {categories.map(({ id, children }) => (
          <Box key={id} sx={{ bgcolor: '#101F33' }}>
            <ListItem sx={{ py: 2, px: 3 }}>
              <ListItemText sx={{ color: '#fff' }}>{id}</ListItemText>
            </ListItem>
            {children.map(({ id: childId, icon, screen }) => (
              <Link style={{ textDecoration: 'none', borderBottomColor:'#6d6d6d' }} to={"/" + screen}>
              <ListItem onClick={() => { setContent(childId) }} disablePadding key={childId}>
                  <ListItemButton selected={childId == content} sx={item}>
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText>{childId}</ListItemText>
                  </ListItemButton>
                </ListItem>
                </Link>
            ))}
            <Divider sx={{ mt: 2 }} />
          </Box>
        ))}
      </List>
    </Drawer>
  );
}
