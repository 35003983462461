import * as React from 'react';
import Typography from '@mui/material/Typography';
import ListApplication from '../requests/List.application'
import ListException from '../requests/List.exception'
import FormDataBuilder from '../utils/FormDataBuilder';
import { Box, ListItem, IconButton, CircularProgress } from '@mui/material';
import { ArrowForwardIos, CheckCircle, DoNotDisturbOnTotalSilence, Error, Help, Info } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import moment from 'moment';

export default function StatusContainer(props) {

    const minVersion = localStorage.getItem("min") ? localStorage.getItem("min") : "0.0.0"

    const [appstoreVersion, setAppstoreVersion] = React.useState("")
    const [playstoreVersion, setPlaystoreVersion] = React.useState("")

    const [serverVersion, setServerVersion] = React.useState({
        idapplication: -1
    })

    const [playstoreLoading, setPlaystoreLoading] = React.useState(true)
    const [appstoreLoading, setAppstoreLoading] = React.useState(true)
    const [levelLoading, setLevelLoading] = React.useState(false)
    const [exLoading, setExLoading] = React.useState(true)
    const [ex, setEx] = React.useState(false)


    const getVersionsFromPlaystore = async (id, pServerVersion) => {
        try {
            const result = await fetch("https://supervision.mobile.otipass.net/tools/sniffer.php?url=" + id)
            const resultTEXT = await result.text()
            const currentVersion = resultTEXT.match(/\[\[\[\"[0-9]*.[0-9]*.[0-9]*\"]]/im).pop().replace(/\[/gm, "").replace(/\"/gm, "").replace(/\]/gm, "")
            setPlaystoreVersion(currentVersion)
            if (currentVersion != pServerVersion.play_store_version) {
                console.log(props.project.access + " " + pServerVersion.play_store_version + " -> " + currentVersion)
                pServerVersion.play_store_version = currentVersion
            }
        } catch (error) { }
        setPlaystoreLoading(false)
    }


    const getVersionsFromAppstore = async (id, pServerVersion) => {
        try {
            const result = await fetch("https://itunes.apple.com/lookup?id=" + id.split("id").pop() + "&ts=" + moment().unix())
            const resultJSON = await result.json()
            if (resultJSON.resultCount == 1) {
                const currentVersion = resultJSON.results[0].version
                setAppstoreVersion(currentVersion)
                if (currentVersion != pServerVersion.apple_store_version) {
                    console.log(props.project.access + " " + pServerVersion.apple_store_version + " -> " + currentVersion)
                    pServerVersion.apple_store_version = currentVersion
                }
            }
        } catch (error) { }
        setAppstoreLoading(false)
    }

    const getProject = async () => {
        let currentApplications = []
        let currentProject = props.project
        if (currentProject.access == "mpm") {
            setAppstoreLoading(false)
            setPlaystoreLoading(false)
        }

        let resultApplication = await new ListApplication(
            new FormDataBuilder()
                .set("project_key", currentProject.access)
        ).emit()
        if (resultApplication.error) {
            //TODO error
        } else {
            let applications = resultApplication.data
            if (applications.length > 0) {
                getEx(currentProject.access)
            }
            for (let indexApplication = 0; indexApplication < applications.length; indexApplication++) {
                const application = applications[indexApplication];
                if (application.type == "1") {
                    currentApplications.push(applications[indexApplication])
                    const pServerVersion = await getVersions()
                    setServerVersion(pServerVersion)
                    getVersionsFromPlaystore(application.playstore, pServerVersion)
                    getVersionsFromAppstore(application.appstore, pServerVersion)
                }
            }
            setAppstoreLoading(false)
            setPlaystoreLoading(false)
        }
    }


    const getEx = async (access) => {
        let exTmp = await new ListException(
            new FormDataBuilder()
                .set('project_key', access)
        ).emit()
        setEx(exTmp.data)
        setExLoading(false)
    }


    const getVersions = async () => {
        try {
            let result = await fetch("https://otipass.net/api/" + props.project.access + "/v2/versions")
            let resultJSON = await result.json()
            if (resultJSON.error == false && resultJSON.data.moon.length > 0) {
                return resultJSON.data.moon.pop()
            }
        } catch (error) { }
        return {
            play_store_version: "0.0.0",
            apple_store_version: "0.0.0",
            otipass_server: "",
            level: 0,
            type: 4
        }
    }

    const isMinVersion = () => {
        let isValidAppStore = true
        let isValidPlayStore = true
        if (minVersion && minVersion.split(".").length == 3) {
            let versionMin = minVersion.split(".")
            let versionPlayStore = playstoreVersion.split(".")
            let versionAppStore = appstoreVersion.split(".")
            if (versionAppStore[0] < versionMin[0]) {
                isValidAppStore = false
            } else if (versionAppStore[0] == versionMin[0] && versionAppStore[1] < versionMin[1]) {
                isValidAppStore = false
            } else if (versionAppStore[0] == versionMin[0] && versionAppStore[1] == versionMin[1] && versionAppStore[2] < versionMin[2]) {
                isValidAppStore = false
            }
            if (versionPlayStore[0] < versionMin[0]) {
                isValidPlayStore = false
            } else if (versionPlayStore[0] == versionMin[0] && versionPlayStore[1] < versionMin[1]) {
                isValidPlayStore = false
            } else if (versionPlayStore[0] == versionMin[0] && versionPlayStore[1] == versionMin[1] && versionPlayStore[2] < versionMin[2]) {
                isValidPlayStore = false
            }
        }
        return { appstore: isValidAppStore, playstore: isValidPlayStore }
    }

    React.useEffect(() => {
        getProject()
    }, [])

    return (
        <ListItem sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', px: 2, py: 1 }}>
            <Typography sx={{ width: 200 }} color="text.primary" >
                {props.project.name}
            </Typography>
            <Box sx={{ width: 100, alignItems: 'center', alignContent: 'center', justifyContent: 'center', alignSelf: 'center', display: 'flex' }}>
                {
                    playstoreLoading ?
                        <CircularProgress size={18} color="inherit" /> :
                        <Typography fontWeight={isMinVersion().playstore ? 'normal' : 'bolder'} sx={{ color: isMinVersion().playstore ? "rgba(0, 0, 0, 0.87)" : "#d32f2f" }} >
                            {playstoreVersion}
                        </Typography>
                }
            </Box>
            {
                appstoreLoading ?
                    <Box sx={{ width: 100, alignItems: 'center', alignContent: 'center', justifyContent: 'center', alignSelf: 'center', display: 'flex' }}>
                        <CircularProgress size={18} color="inherit" />
                    </Box>
                    :
                    <Box style={{ width: 100, textDecoration: 'none', borderBottomColor: '#6d6d6d', alignItems: 'center', alignContent: 'center', justifyContent: 'center', alignSelf: 'center', display: 'flex' }} >
                        <Typography fontWeight={isMinVersion().appstore ? 'normal' : 'bolder'} sx={{ color: isMinVersion().appstore ? "rgba(0, 0, 0, 0.87)" : "#d32f2f" }} >
                            {appstoreVersion}
                        </Typography>
                    </Box>
            }
            <Box sx={{ width: 100, alignItems: 'center', alignContent: 'center', justifyContent: 'center', alignSelf: 'center', display: 'flex' }}>
                <IconButton disabled sx={{ borderRadius: 1, p: 0.5, zIndex: 999999 }}>
                    {
                        serverVersion.idapplication == -1 || levelLoading ?
                            <CircularProgress size={18} color="inherit" />
                            :
                            serverVersion.level == 0 ? <DoNotDisturbOnTotalSilence color='disabled' />
                                :
                                serverVersion.level == 1 ? <Help htmlColor='#4fc3f7' />
                                    :
                                    serverVersion.level == 2 ? <Info htmlColor='#ffb74d' />
                                        :
                                        <Error sx={{ mx: 1 }} color='error' />
                    }
                </IconButton>
            </Box>
            <Box sx={{ width: 50, alignItems: 'center', alignContent: 'center', justifyContent: 'center', alignSelf: 'center', display: 'flex' }}>
                {
                    exLoading ?
                        <CircularProgress key="test" size={18} color="inherit" /> :
                        ex === false ? <Help sx={{ mx: 1 }} color='disabled' /> :
                            ex.length > 0 ?
                                <Error sx={{ mx: 1 }} color='error' /> :
                                <CheckCircle sx={{ mx: 1 }} color='success' />
                }
            </Box>
            <Link style={{ width: 50, textDecoration: 'none', borderBottomColor: '#6d6d6d' }} to={"/application/" + props.project.access}>
                <IconButton>
                    <ArrowForwardIos />
                </IconButton>
            </Link>
        </ListItem>
    )
}
