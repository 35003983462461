import * as React from 'react';
import Paper from '@mui/material/Paper';
import { List, ListItem,Skeleton } from '@mui/material';
export default function BigSkeleton() {

    return (
        <Paper sx={{ maxWidth: 836, margin: 'auto', overflow: 'hidden' }}>
            <List>
                <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }} >
                    <Skeleton style={{ width: '100%', height: 40 }} />
                </ListItem>
                <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }} >
                    <Skeleton style={{ width: '100%', height: 40 }} />
                </ListItem>
                <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }} >
                    <Skeleton style={{ width: '100%', height: 40 }} />
                </ListItem>
                <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }} >
                    <Skeleton style={{ width: '100%', height: 40 }} />
                </ListItem>
                <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }} >
                    <Skeleton style={{ width: '100%', height: 40 }} />
                </ListItem>
                <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }} >
                    <Skeleton style={{ width: '100%', height: 40 }} />
                </ListItem>
                <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }} >
                    <Skeleton style={{ width: '100%', height: 40 }} />
                </ListItem>
                <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }} >
                    <Skeleton style={{ width: '100%', height: 40 }} />
                </ListItem>
                <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }} >
                    <Skeleton style={{ width: '100%', height: 40 }} />
                </ListItem>
                <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }} >
                    <Skeleton style={{ width: '100%', height: 40 }} />
                </ListItem>
            </List>
        </Paper>
    )
}
